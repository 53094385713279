/* eslint-disable import/no-cycle */
import Vue, { AsyncComponent, ComponentOptions } from 'vue';
import Router, { Location, Route, RouteConfig } from 'vue-router';
import routes from './routes';
import AuthGuards from './guards/authentication';
import { I18nLocale } from './types';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;

    if (to.hash) return { selector: to.hash };

    if (to.name === from.name) return savedPosition;

    return { x: 0, y: 0 };
  },
});

router.beforeEach((to: Route, from: Route, next: (any)) => {
  console.debug('[Route]', to.fullPath, to);
  return next();
});
router.beforeEach(AuthGuards.authenticate);
router.beforeEach(AuthGuards.check);

export function getResolvableLocaleLocation(
  $router: Router,
  $route: Route | Location,
  locale: I18nLocale,
): Location {
  const namedLocation: Location = {
    ...$route,
    name: `${($route.name || '').split('#')[0]}#${locale}`,
  };

  if ($router.resolve(namedLocation).resolved.matched.length > 0) {
    return namedLocation;
  }

  const queryLocation: Location = {
    ...$route as Location,
    query: {
      ...$route.query,
      locale,
    },
  };

  return queryLocation;
}

type RouteComponentConfig = ComponentOptions<Vue> | typeof Vue | AsyncComponent;

export function createLocalizedRoute(
  name: string,
  component: RouteComponentConfig,
  localizedRoutes: Record<I18nLocale, string>,
): RouteConfig {
  const [firstLocale, ...otherLocales] = Object.keys(localizedRoutes) as I18nLocale[];

  const parent: RouteConfig = {
    path: localizedRoutes[firstLocale],
    name: `${name}#${firstLocale}`,
    meta: { locale: firstLocale },
    component,
  };

  const children: RouteConfig[] = [];

  otherLocales.forEach((locale) => {
    children.push({
      path: localizedRoutes[locale],
      name: `${name}#${locale}`,
      meta: { locale },
    });
  });

  parent.children = children;

  return parent;
}

export function createRedirect(fromPath: string, toName: string): RouteConfig {
  return {
    path: fromPath,
    redirect: { name: toName },
  };
}

export default router;
