/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/no-cycle */
import { RouteConfig } from 'vue-router';
import NotFound from '@/views/NotFound.vue';
import { createLocalizedRoute, createRedirect } from '@/router';
import About from './about';
import Account from './account';
import Auth from './auth';
import Dashboard from './dashboard';
import Internal from './internal';
import Legal from './legal';
import Profile from './profile';

const routes: RouteConfig[] = [
  createLocalizedRoute('index', () => import(/* webpackChunkName: "v-root" */ '@/views/Index.vue'), {
    en: '/en',
    de: '/de',
  }),
  createRedirect('/', 'index#en'),
  createLocalizedRoute('create', () => import(/* webpackChunkName: "v-create" */ '@/views/Create.vue'), {
    en: '/en/create-maker',
    de: '/de/maker-werden',
  }),
  createRedirect('/create', 'create#en'),
  createLocalizedRoute('new', () => import(/* webpackChunkName: "v-new" */ '@/views/New.vue'), {
    en: '/en/new',
    de: '/de/neu',
  }),
  createRedirect('/new', 'new#en'),
  createRedirect('/neu', 'new#de'),
  ...About,
  ...Account,
  ...Auth,
  ...Dashboard,
  ...Internal,
  ...Legal,
  ...Profile,
  {
    path: '/:route(.*)',
    component: NotFound,
    name: 'not-found',
  },
];

export default routes;
