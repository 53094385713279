





























import Vue from 'vue';
import ListEmpty from '@/components/ListEmpty.vue';
import ListLoading from '@/components/ListLoading.vue';

export default Vue.extend({
  components: {
    ListEmpty,
    ListLoading,
  },
  props: {
    isEmpty: Boolean, // list.length === 0
    isError: Boolean, // Don't show empty if error, but show list if error
    isLoading: Boolean,
  },
});
