









import Vue from 'vue';

export default Vue.extend({
  name: 'BaseLinkReturn',
  inheritAttrs: false,
  props: {
    name: {
      required: true,
      type: String,
    },
  },
  computed: {
    redirect(): any {
      return this.$route.query.redirect ? this.$route.query.redirect : this.$route.fullPath;
    },
  },
});
