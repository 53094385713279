import { Module } from 'vuex';
import { State } from '@/types';
import getters from './getters';
// eslint-disable-next-line import/no-cycle
import actions from './actions';
import mutations from './mutations';

function defaultState(): State.Account {
  return {
    account: null,
    commissionEvents: [],
    contextEvents: [],
    stats: {
      commissions: {
        total: null,
        requested: 0,
        active: 0,
        pending: 0,
        complete: 0,
        stale: 0,
        idle: 0,
        finished: 0,
        archived: 0,
      },
    },
  };
}

const module: Module<State.Account, State.Root> = {
  namespaced: true,
  state: defaultState(),
  getters,
  actions,
  mutations,
};

export default module;
