/* eslint-disable no-param-reassign */
import { VueConstructor } from 'vue';
import { loadStripe } from '@stripe/stripe-js/pure';
import { Stripe } from '@stripe/stripe-js';

// As of 2020-05-23
export const STRIPE_LOCALES = [
  'auto', 'en', 'pt-BR',
  'ar', 'bg', 'cs', 'da', 'de', 'el', 'et', 'es', 'fi', 'fr', 'he', 'id', 'it',
  'jp', 'lt', 'lv', 'ms', 'nb', 'nl', 'pl', 'pt', 'ru', 'sk', 'sl', 'sv', 'zh',
];

// As of 2020-05-23
export const STRIPE_CHECKOUT_LOCALES = [
  'auto', 'en', 'pt-BR',
  'da', 'de', 'es', 'fi', 'fr', 'it', 'jp', 'nb', 'nl', 'pl', 'pt', 'sv', 'zh',
];

export default {
  install(Vue: VueConstructor): void {
    let promisedStripe: Promise<Stripe | null>;

    Vue.prototype.$stripe = async function $stripe(): Promise<Stripe> {
      if (!promisedStripe) {
        promisedStripe = loadStripe(process.env.VUE_APP_STRIPE_PK || 'pk_undefined', {
          apiVersion: '2020-08-27',
          locale: STRIPE_LOCALES.includes(this.$root.$i18n.locale) ? this.$root.$i18n.locale : 'auto',
        });
      }

      return promisedStripe as Promise<Stripe>;
    };
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $stripe(): Promise<Stripe>,
  }
}
