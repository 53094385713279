/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { RouteConfig, RawLocation } from 'vue-router';
import { ApiResource } from '@/types';
import NotFound from '@/views/NotFound.vue';
import { createFathomAnalytics } from '@/analytics/fathom';
// eslint-disable-next-line import/no-cycle
import store, { registerCommissionSubmodules } from '@/store';

const routes: RouteConfig[] = [
  {
    path: '/.well-known/security.txt',
    component: () => import(/* webpackChunkName: "v-l-sec-txt" */ '@/views/legal/SecurityTxt.vue'),
  },
  // :/*-*-*.auftragusercontent.com/file/*/* -> .../?region=$1-$2-$3&bucket=$4#$5
  // query.z => Zone, query.b => b2-bucket, hash => # + file path
  {
    path: '/.cloudflare/auc',
    component: NotFound,
  },
  // auftragapp.com/* -> auftrag.app/.com/*
  {
    path: '/.com/:route(.*)',
    redirect: (to) => ({
      path: to.params.route,
    }) as RawLocation,
  },
  // commissionsmadesimple.com/* -> auftrag.app/.cms/*
  {
    path: '/.cms/:route(.*)',
    redirect: (to) => ({
      path: to.params.route,
    }) as RawLocation,
  },
  // auftr.ag/* -> auftrag.app/.link/*
  {
    path: '/.link/c/:COMMISSION_ID',
    redirect: () => ({
      name: 'commission',
    }) as RawLocation,
  },
  {
    path: '/.link/c/:COMMISSION_ID/f/:FILE_ID',
    redirect: () => ({
      name: 'commission.file',
    }) as RawLocation,
  },
  {
    path: '/.link/c/:COMMISSION_ID/i/:ITEM_ID',
    redirect: () => ({
      name: 'commission.item',
    }) as RawLocation,
  },
  {
    path: '/.link/d/:VANITY',
    redirect: () => ({
      name: 'dashboard',
    }) as RawLocation,
  },
  {
    path: '/.link/d/:VANITY/c/:COMMISSION_ID',
    redirect: () => ({
      name: 'dashboard.commission',
    }) as RawLocation,
  },
  {
    path: '/.link/d/:VANITY/c/:COMMISSION_ID/f/:FILE_ID',
    redirect: () => ({
      name: 'dashboard.commission.file',
    }) as RawLocation,
  },
  {
    path: '/.link/d/:VANITY/c/:COMMISSION_ID/i/:ITEM_ID',
    redirect: () => ({
      name: 'dashboard.commission.item',
    }) as RawLocation,
  },
  {
    path: '/.link/:VANITY~:slug',
    redirect: () => ({
      name: 'profile.page',
    }) as RawLocation,
  },
  {
    path: '/.link/:VANITY',
    redirect: () => ({
      name: 'profile',
    }) as RawLocation,
  },
  {
    path: '/.link/:route(.*)',
    redirect: (to) => ({
      path: to.params.route,
    }) as RawLocation,
  },
  {
    path: '/.stripe/checkout-cancel',
    redirect(to): RawLocation {
      if (to.query.payable_type === 'commission_item') {
        if (!to.query.commission_id) return { name: 'account.commissions' };
        return {
          name: 'commission.items',
          params: {
            COMMISSION_ID: to.query.commission_id.toString(),
            ITEM_ID: to.query.payable_id.toString(),
          },
          query: {
            ...to.query,
            checkout_session_result: 'cancel',
          },
        };
      }
      return { name: 'account' };
    },
  },
  {
    path: '/.stripe/checkout-success',
    redirect(to): RawLocation {
      if (to.query.payable_type === 'commission_item') {
        // Non-blocking way to track goal with collected fee amount
        registerCommissionSubmodules()
          .then(() => store.dispatch('commission/item/Find', {
            COMMISSION_ID: to.query.commission_id as string,
            ITEM_ID: to.query.payable_id as string,
          }))
          .then(({ data: item }: { data: ApiResource.CommissionItem }) => {
            // const $a = createFathomAnalytics();
            // $a.goal($a.goals.CommissionItemPay, item.fee_amount);
          })
          .catch(); // Suppress any errors (i.e. not finding item)

        if (!to.query.commission_id) return { name: 'account.commissions' };
        return {
          name: 'commission.items',
          params: {
            COMMISSION_ID: to.query.commission_id.toString(),
            ITEM_ID: to.query.payable_id.toString(),
          },
          query: {
            ...to.query,
            checkout_session_result: 'success',
          },
        };
      }
      return { name: 'account' };
    },
  },
  {
    path: '/.stripe/onboarding-failure',
    redirect(to): RawLocation {
      let name;
      switch (to.query.route) {
        case 'welcome':
          name = 'dashboard.welcome.step-2';
          break;

        default:
          name = 'dashboard.verification';
          break;
      }

      return {
        name,
        params: {
          VANITY: to.query.business_vanity || to.query.business_id,
        },
        query: {
          ...to.query,
          onboarding_result: 'failure',
        },
      } as RawLocation;
    },
  },
  {
    path: '/.stripe/onboarding-success',
    redirect(to): RawLocation {
      let name;
      switch (to.query.route) {
        case 'welcome':
          name = 'dashboard.welcome.step-2';
          break;

        default:
          name = 'dashboard.verification';
          break;
      }

      return {
        name,
        params: {
          VANITY: to.query.business_vanity || to.query.business_id,
        },
        query: {
          ...to.query,
          onboarding_result: 'success',
        },
      } as RawLocation;
    },
  },
  {
    path: '/.stripe/oauth-connect',
    redirect(to): RawLocation {
      const [BUSINESS_ID] = to.query.state?.toString().split(':');

      return {
        name: 'dashboard.connect',
        params: {
          VANITY: BUSINESS_ID,
        },
        query: {
          ...to.query,
        },
      };
    },
  },
  {
    path: '/internal-prerendered',
    name: 'build.prerender',
    component: () => import(/* webpackChunkName: "v-prerender" */ '@/views/Prerendered.vue'),
  },
  {
    path: '/external-link',
    name: 'external-link',
    component: () => import(/* webpackChunkName: "v-extlnk" */ '@/views/ExternalLink.vue'),
  },
];

export default routes;
