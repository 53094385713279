











import Vue from 'vue';
import { Location } from 'vue-router';
import { getResolvableLocaleLocation } from '@/router';
import { I18nLocale } from '@/types';

export default Vue.extend({
  inheritAttrs: false,
  props: {
    to: {
      type: Object as () => Location,
      required: true,
    },
    hreflang: {
      type: String as () => I18nLocale,
      required: false,
      default: null,
    },
  },
  computed: {
    locale(): I18nLocale {
      return this.hreflang || this.$root.$i18n.locale as I18nLocale;
    },
    resolvableLocation(): Location {
      return getResolvableLocaleLocation(
        this.$router,
        this.to,
        this.locale,
      );
    },
  },
});
