import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { findResource } from '@/util/list';

const getters: GetterTree<State.Profile, State.Root> = {
  Find: (s) => (P: string): ApiResource.Profile | undefined => findResource(s.list, P),
  FindByVanity: (s) => (vanity: string): ApiResource.Profile | undefined => s.list
    .filter((p) => p.vanity === vanity.toLowerCase())[0],
  List: (state) => state.list,
  WatchList: (state) => state.list
    .filter((p) => state.watched.includes(p.id)),
  IsWatched: (state) => (p: string) => state.watched.includes(p),
  PreviouslyCommissioned: (state) => state.list
    .filter((p) => state.previouslyCommissioned.includes(p.id)),
};

export default getters;
