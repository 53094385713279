import jwtDecode from 'jwt-decode';
import { GetterTree } from 'vuex';
import { State } from '@/types';

const getters: GetterTree<State.OAuth2, State.Root> = {
  AccessToken: (state) => state.accessToken,
  AccessTokenParsed: (state) => (state.accessToken ? jwtDecode(state.accessToken) : null),
  AuthorizationHeader: (state) => (state.accessToken ? `${state.accessTokenType} ${state.accessToken}` : null),
  Expired: (state) => (state.expiresAt
    ? state.expiresAt.getTime() < Date.now()
    : true
  ),
  ExpiresAt: (state) => state.expiresAt,
  RefreshToken: (state) => state.refreshToken,
};

export default getters;
