import { Module } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

function defaultState(): State.Auth {
  return {
    deviceToken: window.localStorage.getItem('auth.device_token'),
    isAuthenticated: false,
  };
}

const module: Module<State.Auth, State.Root> = {
  namespaced: true,
  state: defaultState(),
  actions,
  getters,
  mutations,
};

export default module;
