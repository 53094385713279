
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      interval: 0,
      timeout: 30 * 60 * 1000, // 30 Minutes
    };
  },
  created() {
    this.interval = window.setInterval(this.refresh, this.timeout);

    const expiresAt: Date | null = this.$store.getters['oauth2/ExpiresAt'];
    if (!expiresAt) return;

    const diff = expiresAt.getTime() - Date.now() - 5000;

    // Refresh earlier if access token expires before timeout
    // i.e. if less than 30 mins left, refresh 5s before expiring
    // If expiring in less than 5s, refresh immediately
    if (diff < this.timeout) window.setTimeout(this.refresh, ((diff - 5000) <= 0) ? 0 : diff);
  },
  beforeDestroy() {
    window.clearInterval(this.interval);
  },
  methods: {
    async refresh(): Promise<void> {
      this.$store.dispatch('oauth2/refresh')
        .catch(() => this.$router.go(0));
    },
  },
  render: (h) => h(),
});
