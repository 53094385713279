/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { pushResource } from '@/util/list';

const mutations: MutationTree<State.Commission> = {
  Push(state, payload: ApiResource.Commission) {
    pushResource(state.list, payload);
  },
  PushList(state, { data }: { data: ApiResource.Commission[], meta: any }) {
    data.forEach((res) => pushResource(state.list, res));
  },
  PushReview(state, payload: ApiResource.BusinessReview) {
    pushResource(state.reviews, payload);
  },
  PushStats(state, payload: ApiResource.CommissionStats) {
    pushResource(state.stats, payload);
  },
};

export default mutations;
