/* eslint-disable no-param-reassign */
import { VueConstructor } from 'vue';
import { BrowserClient } from '@sentry/browser';
import SentryLoader from '@/sentry-loader';

type SentryClient = SentryLoader & BrowserClient;

export default {
  install(Vue: VueConstructor, Sentry: SentryClient): void {
    Vue.prototype.$sentry = Sentry;
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $sentry: SentryClient,
  }
}
