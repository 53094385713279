











import Vue from 'vue';

export default Vue.extend({
  name: 'BaseNotification',
  props: {
    isDismissable: Boolean,
  },
  methods: {
    dismiss(): void {
      this.$emit('message-dismissed', true);
    },
  },
});
