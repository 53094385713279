















import Vue from 'vue';

export default Vue.extend({
  name: 'BaseTextPlain',
  props: {
    text: {
      type: String,
      required: true,
    },
    hideBlankLines: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    str(): string {
      return this.text.trim();
    },
  },
});
