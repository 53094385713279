
















































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Event, EventHint } from '@sentry/browser';

export default Vue.extend({
  data() {
    return {
      active: false,
      loading: false,
      success: false,
      form: {
        event_id: '',
        name: '',
        email: '',
        comments: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/Account',
      event: 'LAST_SENTRY_EVENT',
    }),
  },
  watch: {
    event: {
      immediate: false,
      handler([event, hint]: [Event, EventHint]) {
        console.debug('[Sentry]', event, hint);
        // this.showReportDialog(event.event_id || '');
      },
    },
  },
  created() {
    this.$sentry.forceLoad();
  },
  beforeDestroy() {
    this.$sentry.flush();
  },
  methods: {
    /**
     * @see https://docs.sentry.io/api/projects/submit-user-feedback/
     * Supports DSN authentication, org/project locked by CSP.
     */
    async submitUserFeedback(): Promise<void> {
      this.success = true;
      await this.$http.post(
        'https://sentry.io/api/0/projects/unovy/auftrag_web/user-feedback/',
        this.form,
        { headers: { Authorization: `DSN ${process.env.VUE_APP_SENTRY_DSN}` } },
      )
        .catch(console.debug);
    },
    showReportDialog(eventId: string) {
      this.form.event_id = eventId;

      if (this.account) {
        this.form.name = this.account.name;
        this.form.email = this.account.email;
      }

      this.active = true;
    },
  },
});
