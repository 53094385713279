import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Business, State.Root> = {
  async ChangeCommissionRequestSettings({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}/commissions`, form);

    commit('Push', res.data);

    return res.data;
  },
  async ChangeDefaultZone({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}/zone`, form);

    commit('Push', res.data);

    return res.data;
  },
  async ChangeExternalAccount({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}/external_accounts`, form);

    commit('PushAccount', res.data.data);

    return res.data;
  },
  async ChangeSearchVisibility({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}/search_visibility`, form);

    commit('Push', res.data);

    return res.data;
  },
  async ChangePricing({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}/pricing`, form);

    commit('Push', res.data);

    return res.data;
  },
  async ChangeVanity({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}/vanity`, form);

    commit('Push', res.data);

    return res.data;
  },
  async Find({ commit }, { BUSINESS_ID }) {
    const res = await Api().get(`businesses/${BUSINESS_ID}`);

    commit('Push', res.data);

    return res.data;
  },
  async GetAccount({ commit }, { BUSINESS_ID }) {
    const res = await Api().get(`businesses/${BUSINESS_ID}/account`);

    commit('PushAccount', res.data.data);

    return res.data;
  },
  async GetBalances({ commit }, { BUSINESS_ID, query }) {
    const res = await Api().get(`businesses/${BUSINESS_ID}/balances`, {
      params: query,
    });

    commit('PushBalances', res.data);

    return res.data;
  },
  async GetAccountLink(context, { BUSINESS_ID, form }) {
    const res = await Api().post(`businesses/${BUSINESS_ID}/account_link`, form);

    return res.data.data;
  },
  async List({ commit }, { query } = {}) {
    const res = await Api().get('businesses', {
      params: query,
    });

    commit('PushList', res.data);

    return res.data;
  },
  async OAuthPrepare(context, { BUSINESS_ID }) {
    const res = await Api().post(`businesses/${BUSINESS_ID}/connect_account`);

    return res.data.data.string;
  },
  async OAuthAuthorize(context, { BUSINESS_ID, form }) {
    const res = await Api().put(`businesses/${BUSINESS_ID}/connect_account`, form);

    return res.data;
  },
  async OAuthDeauthorize(context, { BUSINESS_ID }) {
    const res = await Api().delete(`businesses/${BUSINESS_ID}/connect_account`);

    return res.data;
  },
  async ReceiveWaitlisted({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().post(`businesses/${BUSINESS_ID}/waitlist`, form);

    commit('business/commission/PushList', res.data, { root: true });

    return res.data;
  },
  async SetSlots({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}/slots`, form);

    commit('Push', res.data);

    return res.data;
  },
  async SetWaitlistReceiving({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}/waitlist_receiving`, form);

    commit('Push', res.data);

    return res.data;
  },
  async SetWaitlistSlots({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().put(`businesses/${BUSINESS_ID}/waitlist`, form);

    commit('Push', res.data);

    return res.data;
  },
  async SetWaitlistPublic({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}/waitlist`, form);

    commit('Push', res.data);

    return res.data;
  },
  async UpdatePayoutSchedule({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}/payout_schedule`, form);

    commit('PushAccount', res.data.data);

    return res.data;
  },
  async UpdateProfile({ commit }, { BUSINESS_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}`, form);

    commit('Push', res.data);

    return res.data;
  },
  async UploadBanner(context, { BUSINESS_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}/banner`, form);

    return res.data;
  },
  async UploadIcon(context, { BUSINESS_ID, form }) {
    const res = await Api().patch(`businesses/${BUSINESS_ID}/icon`, form);

    return res.data;
  },
};

export default actions;
