/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { pushResource, removeResource } from '@/util/list';

const mutations: MutationTree<State.Profile.Pages> = {
  Push(state, { data }: { data: ApiResource.Profile.Page }) {
    pushResource(state.list, data);
  },
  PushList(state, { data }: { data: ApiResource.Profile.Page[] }) {
    data.forEach((e) => pushResource(state.list, e));
  },
  RemoveByBusinessId(state, id) {
    state.list
      .filter((p) => p.business_id === id)
      .forEach((p) => removeResource(state.list, p));
  },
};

export default mutations;
