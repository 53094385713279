/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { pushResource } from '@/util/list';

const mutations: MutationTree<State.Business> = {
  Push(state, { data }: { data: ApiResource.Business }) {
    pushResource(state.list, data);
    state.list.sort((a, b) => a.created_at - b.created_at);
  },
  PushAccount(state, payload: ApiResource.Business.Account) {
    pushResource(state.accounts, payload);
  },
  PushBalances(state, { data }: { data: ApiResource.Business.Balances }) {
    pushResource(state.balances, data);
  },
  PushList(state, { data }: { data: ApiResource.Business[], meta: any }) {
    data.forEach((res) => pushResource(state.list, res));
    state.list.sort((a, b) => a.created_at - b.created_at);
  },
};

export default mutations;
