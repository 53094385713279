










import Vue from 'vue';

export default Vue.extend({
  name: 'BaseLinkSupportRequest',
  props: {
    supportVanity: {
      type: String,
      required: false,
      default: 'support',
    },
    supportSummary: {
      type: String,
      required: true,
    },
  },
});
