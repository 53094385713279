/* eslint-disable import/no-cycle */
import { StoreOptions } from 'vuex';
import { State } from '@/types';
import modules from '@/modules';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

declare global {
  interface Window {
    __PRERENDER_INJECTED?: {
      PRERENDERED: boolean,
    },
  }
}

function defaultState(): State.Root {
  const AB = window.localStorage.getItem('ab.segment');

  return {
    ab: {
      segment: AB ? parseInt(AB, 10) : 0,
    },
    api: {
      status: null,
    },
    beacon: {
      count: 0,
      performance: {
        total: null as number | null,
        dns: null as number | null,
        tcp: null as number | null,
        req: null as number | null,
        res: null as number | null,
        dom: null as number | null,
        load: null as number | null,

        wait: null as number | null,
        int: null as number | null,
      },
    },
    build: {
      id: 'undefined',
      commit: 'undefined',
      latestId: 'undefined',
    },
    country: null,
    // eslint-disable-next-line no-underscore-dangle
    isPrerendering: !!window.__PRERENDER_INJECTED,
    online: navigator.onLine,
    sentry: {
      events: [],
    },
    toasts: [],
    version: '2019-04-26',
  };
}

const store: StoreOptions<State.Root> = {
  state: defaultState(),
  strict: process.env.NODE_ENV !== 'production',
  actions,
  getters,
  mutations,
  modules,
};

export default store;
