import { RouteConfig } from 'vue-router';

const routes: RouteConfig[] = [
  {
    path: '/@:VANITY',
    name: '@profile',
    redirect(to) {
      return {
        name: 'profile',
        params: {
          VANITY: to.params.VANITY,
        },
      };
    },
  },
  {
    path: '/maker',
    name: 'profile.permalink',
    component: () => import(/* webpackChunkName: "v-p-pl" */ '@/views/profile/Permalink.vue'),
  },
  {
    path: '/maker/:VANITY',
    component: () => import(/* webpackChunkName: "v-p-root" */ '@/views/profile/Root.vue'),
    children: [
      {
        path: '',
        name: 'profile',
        component: () => import(/* webpackChunkName: "v-p-root" */ '@/views/profile/Index.vue'),
      },
      {
        path: 'submit-request!',
        name: 'profile.request',
        component: () => import(/* webpackChunkName: "v-p-rq" */ '@/views/profile/Request.vue'),
      },
      // {
      //   path: 'follow!',
      //   name: 'profile.follow',
      //   component: () => import(/* webpackChunkName: "v-p-fl" */ '@/views/profile/Follow.vue'),
      // },
      // { // TODO
      //   path: 'share!',
      //   name: 'profile.share',
      //   component: () => import(/* webpackChunkName: "v-p-sh" */ '@/views/profile/Share.vue'),
      // },
      {
        path: '~:slug',
        name: 'profile.page',
        component: () => import(/* webpackChunkName: "v-p-p" */ '@/views/profile/Page.vue'),
      },
    ],
  },
  {
    path: '/:VANITY([a-zA-Z0-9]{3,35})',
    name: 'root-profile',
    redirect(to) {
      return {
        name: 'profile',
        params: {
          VANITY: to.params.VANITY,
        },
      };
    },
  },
];

export default routes;
