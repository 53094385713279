/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import {
  Event as SentryEvent,
  EventHint as SentryEventHint,
} from '@sentry/browser';
import { State } from '@/types';
import { ToastMessageOptions } from '@/plugins/toast';

const mutations: MutationTree<State.Root> = {
  IncrementBeaconCount(state) {
    state.beacon.count += 1;
  },
  SENTRY(state, payload: [SentryEvent, SentryEventHint]) {
    state.sentry.events.unshift(payload);
  },
  SetAbSegment(state, segment?: number) {
    const ab = Math.min(Math.max(segment || 0, 0), 1000);

    state.ab.segment = ab;

    window.localStorage.setItem('ab.segment', ab.toString());
  },
  SetApiStatus(state, status: 'up' | 'down' | 'maintenance' | 'readonly') {
    state.api.status = status;
  },
  SetCountry(state, payload: string) {
    state.country = payload;
  },
  SetCommitRef(state, commitRef: string) {
    state.build.commit = commitRef;
  },
  SetLatestBuildId(state, buildId: string) {
    if (state.build.id === 'undefined') {
      state.build.id = buildId;
    }
    console.debug('[build-info.json]', buildId);
    state.build.latestId = buildId;
  },
  SetOnline(state, payload: boolean) {
    state.online = payload;
  },
  SetPerformance(state, payload: State.Root['beacon']['performance']) {
    state.beacon.performance = payload;
  },
  TOAST(state, payload: ToastMessageOptions) {
    state.toasts.push(payload);
  },
};

export default mutations;
