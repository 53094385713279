






















import Vue from 'vue';

export default Vue.extend({
  name: 'BaseImage',
  inheritAttrs: false,
  props: {
    isRounded: Boolean,
  },
  data() {
    return {
      error: false as Event | boolean,
    };
  },
  watch: {
    '$attrs.src': {
      immediate: true,
      handler(to, from) {
        if (to && to !== from) this.error = false;
        if (!to) this.error = true;
      },
    },
  },
  methods: {
    onError(event: Event) {
      this.error = event;
    },
  },
});
