





























import Vue from 'vue';
import ListEmpty from '@/components/ListEmpty.vue';
import ListLoading from '@/components/ListLoading.vue';

export default Vue.extend({
  components: {
    ListEmpty,
    ListLoading,
  },
  props: {
    isReady: Boolean,
    isError: Boolean,
    isLoading: Boolean,
  },
});
