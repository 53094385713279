import { RouteConfig } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import { createLocalizedRoute, createRedirect } from '@/router';

const routes: RouteConfig[] = [
  createRedirect('/login', 'auth.login#en'),
  createLocalizedRoute('auth.login', () => import(/* webpackChunkName: "v-auth-login" */ '@/views/auth/Login.vue'), {
    en: '/en/login',
    de: '/de/anmelden',
  }),
  {
    path: '/login/:ATTEMPT_ID(.{64}):ATTEMPT_SECRET(.+)/:EMAIL_LINK_SECRET(.+)',
    name: 'auth.login-link',
    component: () => import(/* webpackChunkName: "v-auth-login-link" */ '@/views/auth/LoginLink.vue'),
  },
  createRedirect('/register', 'auth.register#en'),
  createLocalizedRoute('auth.register', () => import(/* webpackChunkName: "v-auth-reg" */ '@/views/auth/Register.vue'), {
    en: '/en/register',
    de: '/de/registrieren',
  }),
  {
    path: '/verify-email',
    name: 'auth.verify-email',
    component: () => import(/* webpackChunkName: "v-auth-ve" */ '@/views/auth/EmailVerify.vue'),
  },
];

export default routes;
