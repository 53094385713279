/* eslint-disable no-underscore-dangle */
import { BrowserOptions } from '@sentry/browser';

const SUPPORTED_INTEGRATIONS = [
  'Vue',
];

/**
 * Class-based webpack-implementation of //js.sentry-cdn.com/*.js
 */
export default class SentryLoader {
  [index: string]: any

  _emulatedMethods = [
    'init',
    'addBreadcrumb',
    'captureMessage',
    'captureException',
    'captureEvent',
    'configureScope',
    'withScope',
    'showReportDialog',
    'setTag',
  ]

  _initOptions: BrowserOptions

  _isPrepared = false

  _callbacks: CallableFunction[] = []

  _queue: any[] = [];

  _originalOnError: any

  _originalOnUnhandledRejecction: any

  _originalConsoleError: any

  _originalVueErrorHandler: any

  _sdkPromise: null | Promise<any> = null

  _integrations: any = {}

  constructor(initOptions: BrowserOptions, integrations: any) {
    this._initOptions = initOptions;
    this._integrations = integrations;

    this._emulatedMethods.forEach((f) => {
      this[f] = (...a: any[]) => {
        this._enqueue({ f, a });
      };
    });

    if (!this._isPrepared) {
      this._isPrepared = true;
      this._claimErrorHandlers();
    }
  }

  onLoad(cb: CallableFunction): void {
    this._callbacks.push(cb);
    this._load(this._callbacks);
  }

  forceLoad(): void {
    this._load(this._callbacks);
  }

  private _enqueue(data: any): void {
    const isCapture = (data.f && data.f.indexOf('capture') > -1);
    const isReport = (data.f && data.f.indexOf('showReportDialog') > -1);

    this._queue.push(data);

    if (data.e || data.p || isCapture || isReport) {
      this._load(this._callbacks);
    }
  }

  private _load(callbacks: CallableFunction[]): void {
    if (this._sdkPromise) return;

    console.debug('[Sentry]', 'Initializing…');

    this._sdkPromise = Promise.all([
      import(/* webpackChunkName: "sentry" */'@sentry/browser'),
      import(/* webpackChunkName: "sentry" */'@sentry/integrations'),
    ]).then(this._init(callbacks));
  }

  private _init(callbacks: CallableFunction[]) {
    return ([SDK, Integrations]: any[]) => {
      this._unclaimErrorHandlers();

      const arr: any[] = [];

      Object.keys(this._integrations).forEach((i) => {
        if (!SUPPORTED_INTEGRATIONS.includes(i)) {
          console.warn('[Sentry-Loader]', i, 'Integration may not be fully supported.');
        }

        arr.push(
          new Integrations[i](this._integrations[i]),
        );
      });

      this._initOptions.integrations = arr;

      SDK.init(this._initOptions);

      console.debug('[Sentry]', 'Initialized', `${SDK.SDK_NAME}@${SDK.SDK_VERSION}`);

      // Apply Sentry SDK over Loader; Loader now extends Sentry
      Object.keys(SDK).forEach((key) => {
        this[key] = SDK[key];
      });

      this._loaded(callbacks);
    };
  }

  private _loaded(callbacks: CallableFunction[]): void {
    const q = this._queue;

    callbacks.forEach((cb) => {
      if (typeof cb === 'function') {
        cb();
      }
    });

    q.forEach((cb) => {
      if (!cb.f) return;

      this[cb.f](...cb.a);
    });

    const tracekitErrorHandler: any = window.onerror;
    const tracekitUnhandledRejectionHandler: any = window.onunhandledrejection;

    q.forEach((d) => {
      if (d.e && tracekitErrorHandler) {
        tracekitErrorHandler.apply(window, d.e);
      } else if (d.p && tracekitUnhandledRejectionHandler) {
        tracekitUnhandledRejectionHandler.apply(window, [d.p]);
      }
    });
  }

  private _claimErrorHandlers(): void {
    this._originalOnError = window.onerror;
    window.onerror = this._onError();

    this._originalOnUnhandledRejecction = window.onunhandledrejection;
    window.onunhandledrejection = this._onUnhandledRejection();

    this._originalConsoleError = console.error;
    console.error = this._onConsoleError();

    if (this._integrations.Vue) this._claimVueErrorHandler();
  }

  private _unclaimErrorHandlers(): void {
    window.onerror = this._originalOnError;
    window.onunhandledrejection = this._originalOnUnhandledRejecction;
    console.error = this._originalConsoleError;

    if (this._integrations.Vue) this._unclaimVueErrorHandler();
  }

  private _onError() {
    // required to be able to access this context (otherwise would be Window)
    return (...args: any[]) => {
      this._enqueue({
        e: [].slice.call(args),
      });

      if (this._originalOnError) {
        this._originalOnError.apply(window, args);
      }
    };
  }

  private _onUnhandledRejection() {
    // required to be able to access this context (otherwise would be Window)
    return (e: PromiseRejectionEvent, ...args: any[]) => {
      this._enqueue({
        p: e.reason,
      });

      if (this._originalOnUnhandledRejecction) {
        this._originalOnUnhandledRejecction.apply(window, [e, ...args]);
      }
    };
  }

  private _onConsoleError() {
    return (e: Error, ...args: any[]): any => {
      this._enqueue({
        e,
      });

      if (this._originalConsoleError) {
        this._originalConsoleError.apply(window, [e, ...args]);
      }
    };
  }

  private _claimVueErrorHandler(): void {
    const vue = this._integrations.Vue.Vue;

    this._originalVueErrorHandler = vue.config.errorHandler;
    vue.config.errorHandler = this._vueErrorHandler();
  }

  private _unclaimVueErrorHandler(): void {
    const vue = this._integrations.Vue.Vue;

    vue.config.errorHandler = this._originalVueErrorHandler;
  }

  private _vueErrorHandler(): CallableFunction {
    const { Vue, attachProps } = this._integrations.Vue;

    return (e: Error, vm: { [key: string]: any }, info: string) => {
      const metadata: any = {};

      // metadata.componentName
      if (attachProps) {
        metadata.propsData = vm.$options.propsData;
      }

      if (info !== undefined) {
        metadata.lifecycleHook = info;
      }

      this.withScope((scope: any) => {
        Object.keys(metadata).forEach((k) => {
          scope.setExtra(k, metadata[k]);
        });
      });

      this.captureException(e);

      if (this._originalVueErrorHandler) {
        this._originalVueErrorHandler.call(Vue, e, vm, info);
      }
    };
  }
}
