import { GetterTree } from 'vuex';
import { State } from '@/types';

const getters: GetterTree<State.Root, State.Root> = {
  AB_SEGMENT: (state) => state.ab.segment,
  API_STATUS: (state) => state.api.status,
  BEACON_COUNT: (state) => state.beacon.count,
  BUILD_ID: (state) => state.build.id,
  COMMIT_REF: (state) => state.build.commit,
  COUNTRY: (state) => state.country,
  IS_API_DOWN: (state) => state.api.status === 'down' || state.api.status === 'CF-down',
  IS_CF_DOWN: (state) => state.api.status === 'CF-down',
  IS_API_MAINTENANCE: (state) => state.api.status === 'maintenance',
  IS_API_READONLY: (state) => state.api.status === 'readonly',
  IS_API_UP: (state) => state.api.status === 'up',
  IS_BUILD_OUTDATED: (state) => state.build.id !== state.build.latestId,
  IS_ONLINE: (state) => state.online,
  IS_PRERENDERING: (state) => state.isPrerendering,
  LAST_SENTRY_EVENT: (state) => state.sentry.events[0],
  PERFORMANCE: (state) => state.beacon.performance,
};

export default getters;
