import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Vanity, State.Root> = {
  async Check({ commit, getters, state }, vanity: string) {
    if (getters.IsChecked(vanity)) {
      return state.list.filter(
        (v) => v.vanity === vanity.toLowerCase(),
      )[0];
    }

    const res = await Api().get('vanity', {
      params: { vanity: vanity.toLowerCase() },
    });

    commit('Push', res.data.data);

    return res.data.data;
  },
};

export default actions;
