/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { MutationTree } from 'vuex';
import { State } from '@/types';

const mutations: MutationTree<State.Auth> = {
  DeviceToken(state, deviceToken: string | null) {
    if (!deviceToken) {
      state.deviceToken = null;

      window.localStorage.removeItem('auth.device_token');

      return;
    }

    state.deviceToken = deviceToken;
    window.localStorage.setItem('auth.device_token', deviceToken);
  },
};

export default mutations;
