import Vue from 'vue';
import { Store } from 'vuex';
import SentryLoader from './sentry-loader';

interface CreateSentryOptions {
  store: Store<any>,
  commit: string,
}

export default function createSentry({ store, commit }: CreateSentryOptions): SentryLoader {
  return new SentryLoader({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    release: process.env.VUE_APP_COMMIT_REF,
    environment: process.env.NODE_ENV,
    debug: process.env.NODE_ENV === 'development',
    beforeSend(event, hint) {
      store.commit(commit, [event, hint]);

      return event;
    },
  }, {
    Vue: {
      Vue,
      attachProps: true,
      logErrors: process.env.NODE_ENV === 'development',
    },
  });
}
