import { NavigationGuard } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
// eslint-disable-next-line import/no-cycle
import router, { getResolvableLocaleLocation } from '@/router';
import i18n from '@/i18n';
import { I18nLocale } from '@/types';

const debug = (code: number): void => console.debug('[Guard]', code);

const authenticate: NavigationGuard = async (to, from, next) => {
  let shouldAwait = true;

  if (!to.matched.some((record) => record.meta.requireAuth)) shouldAwait = false;

  debug(shouldAwait ? 0 : -0);

  // if !refreshToken: Logout
  // if refreshToken
  //   if !accessToken: Refresh access token
  //   if accessToken && accessTokenExpired: Refresh access token
  //   if accessToken: next()

  const refreshToken = store.getters['oauth2/RefreshToken'];
  let accessToken = store.getters['oauth2/AccessToken'];
  const isExpired = store.getters['oauth2/Expired'];

  // May have valid, non-expired access token
  if (accessToken && !isExpired) {
    debug(1); // May have valid accessToken.
    // Have stored account?
    if (store.getters['account/IsLoggedIn']) {
      debug(-1); // OK, Is logged in.

      return next();
    }

    debug(2); // Account not stored

    // Get account before proceeding
    let error;

    if (shouldAwait) {
      try {
        await store.dispatch('auth/login');
      } catch (e) {
        // AccessToken may be invalid or expired
        console.error(e);
        error = e;
        debug(3); // Error logging in. Account not loaded.

        accessToken = null;
      }

      if (!error) {
        debug(-3); // No error. Logged in.

        return next();
      }
    } else {
      store.dispatch('auth/login')
        .then(() => debug(4)) // Did login
        .catch(console.error);

      debug(-4); // Not awaiting, will login eventually…

      return next();
    }

    debug(5); // Access token was invalid.
  }

  // No RefreshToken
  if (!refreshToken) {
    debug(6); // No refresh token.

    // Wasn't logged in anyway?
    // if (!store.getters['account/IsLoggedIn']) return next();

    // console.debug('[Auth]', -1);

    return next();
  }

  // May have valid RefreshToken

  // Missing or expired AccessToken
  if (!accessToken || isExpired) {
    debug(7); // Missing or expired access token.

    if (shouldAwait) {
      try {
        // Attempt to refresh AccessToken
        await store.dispatch('oauth2/refresh');
        await store.dispatch('auth/login');
      } catch (e) {
        // Refreshtoken invalid
        console.error(e);
        debug(-7); // Invalid refresh token.

        return next();
      }

      debug(-8); // Logged in after missing access token.

      return next();
    }
    store.dispatch('oauth2/refresh')
      .then(() => store.dispatch('auth/login'))
      .then(() => debug(9))
      .catch(console.error);

    debug(-9); // Not awaiting, refresh and login eventually…

    return next();
  }

  debug(-10); // Invalid final state

  return next();
};

const check: NavigationGuard = async (to, from, next) => {
  // Check if route requires auth, PASS if false
  if (!to.matched.some((record) => record.meta.requireAuth)) return next();

  // Check if authenticated, PASS if true
  console.debug('[Check]', 'Route requires authentication.');
  if (store.getters['account/IsLoggedIn']) return next();
  // Not authenticated, route requires auth, FAIL: login
  console.debug('[Check]', 'Not authenticated, redirecting to login.');

  return next(getResolvableLocaleLocation(router, {
    name: 'auth.login',
    query: {
      redirect: to.fullPath,
    },
  }, i18n.locale as I18nLocale));
};

export default { authenticate, check };
