import { VueConstructor } from 'vue';

const FeatureFlagsPlugin = {
  install(Vue: VueConstructor, options: string[]): void {
    console.debug('[FeatureFlags]', JSON.stringify(options));

    function featureflag(flag: string): boolean {
      return options.includes(flag);
    }

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$ff = new Proxy(featureflag, {
      get(o, key: string): boolean {
        return featureflag(key);
      },
    });
  },
};

type FeatureFlags = {
  (flag: string): boolean,
  [key: string]: boolean,
};

export default FeatureFlagsPlugin;

declare module 'vue/types/vue' {
  interface Vue {
    $ff: FeatureFlags,
  }
}
