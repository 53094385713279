/* eslint-disable no-param-reassign */
import { AnalyticsInterface } from '@/analytics/index';
import { VueConstructor } from 'vue';

export default {
  install(Vue: VueConstructor, analytics: AnalyticsInterface): void {
    Vue.prototype.$analytics = analytics;
    Vue.prototype.$a = analytics;
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $analytics: AnalyticsInterface,
    $a: AnalyticsInterface,
  }
}
