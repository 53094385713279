import JwtDecode from 'jwt-decode';
import { Module } from 'vuex';
import { State, OAuthTwoAccessToken } from '@/types';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

function defaultState(): State.OAuth2 {
  const accessToken = window.localStorage.getItem('oauth2.access_token');
  let parsed: OAuthTwoAccessToken|null;
  let expiresAt: Date|null = null;

  if (accessToken) {
    console.debug('[OAuth2]', 'Recovering access token…');
    try {
      parsed = JwtDecode<OAuthTwoAccessToken>(accessToken);

      expiresAt = new Date(parsed.exp * 1000);
    } catch (e) {
      parsed = null;
    }
  }

  return {
    accessToken,
    accessTokenType: 'Bearer',
    expiresAt,
    refreshToken: window.localStorage.getItem('oauth2.refresh_token'),
  };
}

const module: Module<State.OAuth2, State.Root> = {
  namespaced: true,
  state: defaultState(),
  getters,
  actions,
  mutations,
};

export default module;
