















import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'BaseLinkEmail',
  props: {
    to: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      IS_PRERENDERING: 'IS_PRERENDERING',
      AB_SEGMENT: 'AB_SEGMENT',
    }),
    mailto(): string {
      return `mailto:${this.to}`;
    },
  },
});
