











import Vue from 'vue';

export default Vue.extend({
  name: 'BaseLinkSupport',
  props: {
    supportUrl: {
      type: String,
      required: false,
      default: 'home',
      // - Article:        "solutions/article/:article_id"
      // - Folder:         "solutions/folder/:folder_id"
      // - Category:       "solutions/:category_id"
      // - Knowledge base: "solutions"
      // - Landing:        "home"
      // - New ticket:     "tickets/new"
      // - View tickets:   "tickets" (Login required)
      // - View ticket:    "tickets/:ticket_id" (Login required)
      // - Contact info:   "profile/edit" (Login required)
      // - Forums:         ?
    },
  },
  computed: {
    url(): string {
      return `https://${process.env.VUE_APP_SUPPORT_HOSTNAME}/support/${this.supportUrl}`;
    },
  },
});
