/* eslint-disable import/no-cycle */
import { Module } from 'vuex';
import { State } from '@/types';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import pages from './pages';

function defaultState(): State.Profile {
  return {
    list: [],
    watched: [],
    previouslyCommissioned: [],
  };
}

const module: Module<State.Profile, State.Root> = {
  namespaced: true,
  state: defaultState(),
  getters,
  actions,
  mutations,
  modules: {
    pages,
  },
};

export default module;
