import { RouteConfig } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import { createLocalizedRoute, createRedirect } from '@/router';

const routes: RouteConfig[] = [
  createLocalizedRoute('legal.notice', () => import(/* webpackChunkName: "v-l-im" */ '@/views/legal/Notice.vue'), {
    en: '/en/legal-notice',
    de: '/de/impressum',
  }),
  createRedirect('/legal/notice', 'legal.notice#en'),
  createRedirect('/imprint', 'legal.notice#en'),
  createRedirect('/impressum', 'legal.notice#de'),

  createLocalizedRoute('legal.terms', () => import(/* webpackChunkName: "v-l-agb" */ '@/views/legal/Terms.vue'), {
    en: '/en/terms',
    de: '/de/nutzungsbedingungen',
  }),
  createRedirect('/legal/terms', 'legal.terms#en'),
  createRedirect('/terms', 'legal.terms#en'),
  createRedirect('/agb', 'legal.terms#de'),

  createLocalizedRoute('legal.privacy', () => import(/* webpackChunkName: "v-l-pp" */ '@/views/legal/Privacy.vue'), {
    en: '/en/privacy',
    de: '/de/datenschutz',
  }),
  createRedirect('/legal/privacy', 'legal.privacy#en'),
  createRedirect('/privacy', 'legal.privacy#en'),
  createRedirect('/datenschutz', 'legal.privacy#de'),
  createRedirect('/dse', 'legal.privacy#de'),

  createLocalizedRoute('legal.security', () => import(/* webpackChunkName: "v-l-sec" */ '@/views/legal/Security.vue'), {
    en: '/en/security',
    de: '/de/sicherheit',
  }),
  createRedirect('/legal/security', 'legal.security#en'),
  createRedirect('/security', 'legal.security#en'),
  createRedirect('/sicherheit', 'legal.security#de'),

  createLocalizedRoute('legal.coc', () => import(/* webpackChunkName: "v-l-coc" */ '@/views/legal/Conduct.vue'), {
    en: '/en/conduct',
    de: '/de/verhaltensregeln',
  }),
  createRedirect('/legal/conduct', 'legal.coc#en'),

  {
    path: '/en/open-source',
    name: 'legal.foss',
    component: () => import(/* webpackChunkName: "v-l-foss" */ '@/views/legal/OpenSource.vue'),
  },
  createRedirect('/legal/open-source', 'legal.foss'),
];

export default routes;
