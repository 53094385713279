import { AxiosError } from 'axios';
import { VueConstructor } from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter, { Location } from 'vue-router';
import { Store } from 'vuex';
import ToastMessageComponent from './Toast.vue';

export interface ToastMessageOptions {
  message?: string,
  duration?: number,
  onClose?: CallableFunction,
  queue?: boolean,
  pauseOnHover?: boolean,
  classes?: any,
  position?: 'top-left' | 'top' | 'top-right' | 'bottom-left' | 'bottom' | 'bottom-right',
  actions?: {
    label: string,
    handler?: CallableFunction,
    dismiss?: boolean,
    classes?: any,
  }[],
  links?: {
    label: string,
    to: Location,
    dismiss?: boolean,
    classes?: any,
  }[],
}

function isAxiosError(error: any): error is AxiosError {
  return error.isAxiosError === true;
}

class Toaster {
  vue: VueConstructor

  i18n: VueI18n

  router: VueRouter

  store: Store<any>

  commit: string

  constructor(
    Vue: VueConstructor,
    i18n: VueI18n,
    router: VueRouter,
    store: Store<any>,
    commit: string,
  ) {
    this.vue = Vue;
    this.i18n = i18n;
    this.router = router;
    this.store = store;
    this.commit = commit;
  }

  open(messageOptions: ToastMessageOptions): any {
    const propsData = { ...messageOptions };

    this.store.commit(this.commit, messageOptions);

    return new (this.vue.extend(ToastMessageComponent))({
      el: document.createElement('div'),
      propsData,
      router: this.router, // Required to support RouterLink
    });
  }

  error(error: Error | AxiosError): any {
    let { message } = error;

    if (isAxiosError(error)) {
      // is API error
      if (error.response?.headers['auf-request-id']
        && error.response?.data.error) {
        const body = error.response.data;

        if (error.response?.status === 401 && body.hint) {
          message = body.hint;
        } else {
          const { type, code } = body.error;

          message = this.i18n.t(`e.api.${type}.${code}`).toString();
        }
      }
    }

    return this.open({
      message,
      classes: 'is-danger',
      position: 'top',
    });
  }
}

export interface ToastPluginOptions {
  i18n: VueI18n,
  router: VueRouter,
  store: Store<any>,
  commit: string,
}

export default {
  install(Vue: VueConstructor, {
    i18n, router, store, commit,
  }: ToastPluginOptions): void {
    Vue.component('ToastMessage', ToastMessageComponent);

    const toaster = new Toaster(Vue, i18n, router, store, commit);

    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$toast = (messageOptions: ToastMessageOptions) => toaster.open(messageOptions);
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$toastSuccess = (
      message: string,
      messageOptions: ToastMessageOptions = {},
    ) => toaster.open({
      classes: 'is-link',
      position: 'bottom',
      ...messageOptions,
      message,
    });
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$toastError = (error: Error) => toaster.error(error);
    // eslint-disable-next-line no-param-reassign
    Vue.prototype.$et = (error: Error) => toaster.error(error);
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $toast(messageOptions: ToastMessageOptions): void,
    $toastSuccess(message: string, messageOptions?: ToastMessageOptions): void,
    $toastError(error: Error): void,
    $et(error: Error): void,
  }
}
