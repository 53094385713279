import Vue from 'vue';
import AnalyticsPlugin from '@/plugins/analytics';
import DateFnsPlugin from '@/plugins/date-fns';
import FeatureFlagsPlugin from '@/plugins/feature-flags';
import HttpPlugin from '@/plugins/http';
import SentryPlugin from '@/plugins/sentry';
import StripePlugin from '@/plugins/stripe';
// eslint-disable-next-line import/no-cycle
import ToastPlugin from '@/plugins/toast';
import App from './App.vue';
import router from './router';
import store from './store';
import { createAxios } from './http';
import i18n from './i18n';
import i18nDateFns from './i18n-date-fns';
import meta from './meta';
import createSentry from './sentry';
import './components';
// import { createConsoleLogAnalytics } from './analytics/console';
import { createConsoleLogAnalytics } from './analytics/console';
// import ScriptLoader from './util/script-loader';

// eslint-disable-next-line no-constant-condition
Vue.use(AnalyticsPlugin, createConsoleLogAnalytics());
Vue.use(DateFnsPlugin, {
  locales: i18nDateFns,
  i18n,
});
Vue.use(FeatureFlagsPlugin, (process.env.VUE_APP_FEATURE_FLAGS || '').split(','));
Vue.use(HttpPlugin, createAxios());
Vue.use(SentryPlugin, createSentry({ store, commit: 'SENTRY' }));
Vue.use(StripePlugin);
Vue.use(ToastPlugin, {
  router, store, commit: 'TOAST', i18n,
});

Vue.config.productionTip = false;

const main = new Vue({
  router,
  store,
  i18n,
  meta,
  render: (h) => h(App),
});

main.$mount('#app');

if (process.env.NODE_ENV === 'development') {
  // Allow access to root instance in console
  (globalThis as { [key: string]: any }).auftrag = main;
}

export default main;

export const Bus = new Vue();
