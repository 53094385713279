































import Vue from 'vue';
import NotFound from '@/components/NotFound.vue';

export default Vue.extend({
  components: {
    NotFound,
  },
  meta() {
    const title = this.$t('not-found.title').toString();
    const desc = this.$t('not-found.subtitle').toString();

    return {
      title,
      meta: [
        {
          vmID: 'og:title',
          property: 'og:title',
          content: `${title} – Auftrag.app`,
        },
        {
          vmID: 'og:description',
          property: 'og:description',
          content: desc,
        },
      ],
    };
  },
});
