
import Vue from 'vue';
import { MetaInfo } from 'vue-meta';

export default Vue.extend({
  name: 'MetaInfo',
  props: {
    theDescription: {
      type: String,
      required: false,
      default: null,
    },
    theTitle: {
      type: String,
      required: false,
      default: null,
    },
  },
  render: (h) => h(),
  meta(): MetaInfo {
    const meta: MetaInfo['meta'] = [];

    if (this.theTitle) {
      meta.push({
        vmID: 'og:title',
        property: 'og:title',
        content: this.theTitle,
      },
      {
        vmID: 'twitter:title',
        name: 'twitter:title',
        content: this.theTitle,
      });
    }

    if (this.theDescription) {
      meta.push({
        vmID: 'description',
        name: 'description',
        content: this.theDescription,
      },
      {
        vmID: 'og:description',
        property: 'og:description',
        content: this.theDescription,
      },
      {
        vmID: 'twitter:description',
        name: 'twitter:description',
        content: this.theDescription,
      });
    }

    return { meta };
  },
});
