
import Vue from 'vue';
import { Location, Route } from 'vue-router';

export default Vue.extend({
  watch: {
    $route: {
      immediate: false,
      deep: false,
      handler(to: Route) {
        console.debug('[Analytics] Pageview', to.fullPath);
        if (to.matched.some((v) => v.meta.requireAuth)) {
          // Remove resource IDs from authenticated routes.
          const page = this.$router.resolve({
            ...to as Location,
            params: {
              COMMISSION_ID: ':commission',
              FILE_ID: ':file',
              ITEM_ID: ':item',
              VANITY: ':vanity',
            },
          });

          this.$a.pageview(process.env.VUE_APP_BASE_URL + page.href);
        } else {
          this.$a.pageview();
        }
      },
    },
  },
  render: (h) => h(),
});
