import Axios, { AxiosInstance, AxiosResponse } from 'axios';
import { UploadInstructions } from '@/types';
// eslint-disable-next-line import/no-cycle
import store from './store';
import i18n from './i18n';

export const http = Axios.create();

export const createAxios = (): AxiosInstance => Axios.create();

export const Api = (AuthenticationToken?: string): AxiosInstance => {
  const instance = Axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    responseType: 'json',
    maxRedirects: 0,
    withCredentials: false,
    validateStatus(status) {
      return status >= 200 && status < 300;
    },
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': i18n.locale,
    },
  });

  if (store.getters['oauth2/AuthorizationHeader']) {
    instance.defaults.headers.Authorization = store.getters['oauth2/AuthorizationHeader'];
  }

  if (AuthenticationToken) {
    instance.defaults.headers['Authentication-Token'] = AuthenticationToken;
  }

  // TODO: Consider using response insterceptor to refresh token, retry request automatically.
  // instance.interceptors.response.use(
  //   response => response,
  //   async (error) => {
  //     if (error.response?.status === 401) {
  //       await store.dispatch('oauth2/refresh');
  //     }
  //     return error;
  //   },
  // );

  // API Status Interceptor
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      // API responds with maintenance status
      if (error.response?.status === 503) {
        store.commit('SetApiStatus', error.response?.data?.error?.type === 'readonly' ? 'readonly' : 'maintenance');
        throw error;
      }

      // API has other error
      if (error.response && error.response?.status !== 503) throw error;

      // Browser is offline, doesn't matter if down
      if (!navigator.onLine) throw error;

      // Check if API is up/down via Cloudflare Worker
      if (!store.getters.IS_CF_DOWN) store.dispatch('info');

      throw error;
    },
  );

  return instance;
};

export const Upload = (
  instructions: UploadInstructions,
  file: File,
  onUploadProgress: (event: ProgressEvent) => void,
): Promise<AxiosResponse<any>> => {
  if (instructions.object !== 'upload') throw new Error('Expected instructions object.');

  return Axios.request({
    method: instructions.method,
    url: instructions.url,
    headers: instructions.headers,
    params: instructions.query,
    data: instructions.params,
    onUploadProgress,
    validateStatus(status) {
      return status === 204 || status === 200;
    },
    transformRequest: [
      // FormData Handler
      (data) => {
        if (!instructions.file.startsWith('FORM_DATA_APPEND_PARAM')) return data;

        const form = new FormData();
        const params = Object.keys(data);

        params.forEach((param) => form.append(param, data[param]));

        form.append(instructions.file.split(':', 2)[1], file);

        return form;
      },
      (data) => {
        if (instructions.file !== 'POST_REQUEST_BODY') return data;

        return file;
      },
    ],
  });
};

export default {
  Api, Upload, http, createAxios,
};
