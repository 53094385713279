/* eslint-disable no-param-reassign */
import { VueConstructor } from 'vue';
import { AxiosInstance } from 'axios';

export default {
  install(Vue: VueConstructor, http: AxiosInstance): void {
    Vue.prototype.$http = http;
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $http: AxiosInstance,
  }
}
