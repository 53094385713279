/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { MutationTree } from 'vuex';
import { State } from '@/types';

const mutations: MutationTree<State.OAuth2> = {
  tokens(state, payload: OAuthTwoTokenResponse | null) {
    if (!payload) {
      state.accessToken = null;
      state.expiresAt = null;
      state.refreshToken = null;

      window.localStorage.removeItem('oauth2.access_token');
      window.localStorage.removeItem('oauth2.refresh_token');

      return;
    }

    state.accessToken = payload.access_token;
    state.expiresAt = new Date(Date.now() + (payload.expires_in * 1000));
    state.refreshToken = payload.refresh_token;

    window.localStorage.setItem('oauth2.access_token', payload.access_token);
    window.localStorage.setItem('oauth2.refresh_token', payload.refresh_token);
  },
};

export default mutations;

export interface OAuthTwoTokenResponse {
  access_token: string,
  expires_in: number,
  refresh_token: string,
  token_type: 'Bearer',
}
