import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Commission, State.Root> = {
  async Approve({ commit }, { COMMISSION_ID }) {
    const res = await Api().post(`commissions/${COMMISSION_ID.toLowerCase()}/approve`);

    commit('Push', res.data.data);

    return res.data;
  },
  async CancelRequest({ commit }, { COMMISSION_ID }) {
    const res = await Api().post(`commissions/${COMMISSION_ID.toLowerCase()}/cancel`);

    commit('Push', res.data.data);

    return res.data;
  },
  async Disapprove({ commit }, { COMMISSION_ID }) {
    const res = await Api().post(`commissions/${COMMISSION_ID.toLowerCase()}/disapprove`);

    commit('Push', res.data.data);

    return res.data;
  },
  async Find({ commit }, { COMMISSION_ID }) {
    const res = await Api().get(`commissions/${COMMISSION_ID.toLowerCase()}`);

    commit('Push', res.data.data);

    return res.data;
  },
  async GetReview({ commit }, { COMMISSION_ID }) {
    const res = await Api().get(
      `commissions/${COMMISSION_ID.toLowerCase()}/review`,
    );

    commit('PushReview', res.data.data);

    return res.data;
  },
  async List({ commit }, { query } = { limit: 10 }) {
    const res = await Api().get('commissions', {
      params: query,
    });

    commit('PushList', res.data);

    return res.data;
  },
  async ReviewBusiness({ commit }, { COMMISSION_ID, form }) {
    const res = await Api().put(
      `commissions/${COMMISSION_ID.toLowerCase()}/review`,
      form,
    );

    commit('PushReview', res.data.data);

    return res.data;
  },
  async Stats({ commit }, { COMMISSION_ID }) {
    const res = await Api().get(`commissions/${COMMISSION_ID.toLowerCase()}/stats`);

    commit('PushStats', res.data.data);

    return res.data;
  },
  async Touch({ commit }, { COMMISSION_ID }) {
    const res = await Api().post(`commissions/${COMMISSION_ID.toLowerCase()}/touch`);

    commit('Push', res.data.data);

    return res.data;
  },
};

export default actions;
