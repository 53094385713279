


















































import Vue from 'vue';

class Timer {
  start: number

  cb: CallableFunction

  ms: number

  to: number

  constructor(cb: CallableFunction, duration: number) {
    this.start = Date.now();
    this.cb = cb;
    this.ms = duration;
    this.to = window.setTimeout(this.cb, this.ms);
  }

  pause(): void {
    this.stop();
    this.ms -= (Date.now() - this.start);
  }

  resume(): void {
    this.stop();
    this.start = Date.now();
    this.to = window.setTimeout(this.cb, this.ms);
  }

  stop(): void {
    window.clearTimeout(this.to);
  }
}

function removeElement(el: Element): void {
  if (typeof el.remove !== 'undefined') el.remove();
  else el.parentNode?.removeChild(el);
}

export default Vue.extend({
  name: 'ToastMessage',
  props: {
    message: {
      type: String,
      required: true,
    },
    duration: {
      type: Number,
      required: false,
      default: 10000,
    },
    onClose: {
      type: Function,
      required: false,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => { },
    },
    queue: Boolean,
    dismissible: {
      type: Boolean,
      required: false,
      default: true,
    },
    pauseOnHover: {
      type: Boolean,
      required: false,
      default: true,
    },
    position: {
      type: String,
      required: false,
      default: 'top-left',
    },
    classes: {
      type: [String, Array, Object],
      required: false,
      default: '',
    },
    messageClasses: {
      type: [String, Array, Object],
      required: false,
      default: '',
    },
    actions: {
      type: Array,
      required: false,
      default: () => [],
    },
    links: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      isActive: false,
      timer: null as Timer | null,
      queueTimeout: null as number | null,
      parentTop: null as HTMLElement | null,
      parentBottom: null as HTMLElement | null,
    };
  },
  computed: {
    shouldQueue(): boolean {
      if (!this.queue) return false;

      return (this.parentTop?.childElementCount || 0) > 0
       || (this.parentBottom?.childElementCount || 0) > 0;
    },
    parent(): Element | null {
      switch (this.position) {
        case 'top':
        case 'top-left':
        case 'top-right':
          return this.parentTop;

        case 'bottom':
        case 'bottom-left':
        case 'bottom-right':
        default:
          return this.parentBottom;
      }
    },
    positionClass(): string {
      switch (this.position) {
        case 'bottom':
        case 'top':
          return 'toast-message-center';

        case 'bottom-left':
        case 'top-left':
          return 'toast-message-left';

        case 'top-right':
        case 'bottom-right':
        default:
          return 'toast-message-right';
      }
    },
    transition(): any {
      switch (this.position) {
        case 'top':
        case 'top-left':
        case 'top-right':
          return {
            enter: 'fadeInDown',
            leave: 'fadeOut',
          };

        case 'bottom':
        case 'bottom-left':
        case 'bottom-right':
        default:
          return {
            enter: 'fadeInUp',
            leave: 'fadeOut',
          };
      }
    },
  },
  beforeMount() {
    this.prepare();
  },
  mounted() {
    this.show();
  },
  methods: {
    prepare(): void {
      this.parentTop = document.querySelector('#app-toast-top');
      this.parentBottom = document.querySelector('#app-toast-bottom');

      if (this.parentTop && this.parentBottom) return;

      const container = document.body;

      if (!this.parentTop) {
        this.parentTop = document.createElement('div');
        this.parentTop.id = 'app-toast-top';
        this.parentTop.classList.add('toast-container', 'toast-top');
        container.appendChild(this.parentTop);
      }

      if (!this.parentBottom) {
        this.parentBottom = document.createElement('div');
        this.parentBottom.id = 'app-toast-bottom';
        this.parentBottom.classList.add('toast-container', 'toast-bottom');
        container.appendChild(this.parentBottom);
      }
    },
    close(): void {
      this.timer?.stop();
      window.clearTimeout(this.queueTimeout || -1);
      this.isActive = false;

      window.setTimeout(() => {
        this.$destroy();
        removeElement(this.$el);
      }, 150);
    },
    show(): void {
      if (this.shouldQueue) {
        this.queueTimeout = window.setTimeout(this.show, 250);
        return;
      }

      this.parent?.insertAdjacentElement('afterbegin', this.$el);
      this.isActive = true;
      this.timer = new Timer(this.close, this.duration);
    },
    toggle(pause: boolean): void {
      if (!this.pauseOnHover) return;
      pause ? this.timer?.pause() : this.timer?.resume();
    },
    dismiss(): void {
      if (!this.dismissible) return;
      this.close();
    },
    handleAction(action: any): void {
      if (action.handler) action.handler();
      if (action.dismiss) this.dismiss();
    },
  },
});
