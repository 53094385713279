











import Vue from 'vue';

export default Vue.extend({
  name: 'BaseTagVerified',
  props: {
    isShort: Boolean,
  },
});
