import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { findResource } from '@/util/list';

const getters: GetterTree<State.Commission, State.Root> = {
  Find: (s) => (C: string): ApiResource.Commission | undefined => findResource(s.list, C),
  List: (s) => s.list,
  FindReview: (s) => (B: string): ApiResource.BusinessReview | undefined => s.reviews
    .filter((r) => r.business_id === B)[0],
  Stats: (s) => (C: string): ApiResource.CommissionStats | undefined => findResource(s.stats, C),
};

export default getters;
