import { ActionTree } from 'vuex';
import Axios from 'axios';
import { State } from '@/types';
import sleep from '@/util/sleep';
// eslint-disable-next-line import/no-cycle
import router from '@/router';

const http = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  responseType: 'json',
  maxRedirects: 0,
  withCredentials: false,
});

interface AuthRequest {
  purpose: 'OAuth2.Token.Password',
  email: string,
  password: string,
  code: string | null,
}

const ROOT = { root: true };

const actions: ActionTree<State.Auth, State.Root> = {
  // $store.dispatch('auth/authenticate', {email, password, code, purpose})
  async authenticate(context, form: AuthRequest) {
    const res = await http.post('authenticate', form);
    return res.data.data;
  },
  login({ commit, dispatch }, payload) {
    if (payload) commit('oauth2/tokens', payload, ROOT);
    dispatch('business/List', undefined, ROOT); // non-critical, just needed eventually
    return dispatch('account/GetAccount', undefined, ROOT);
  },
  logout({ commit }) {
    commit('oauth2/tokens', null, ROOT);
    router.go(0);
  },
  async register(context, form) {
    const res = await http.post('register', form);
    return res.data.data;
  },

  'Register+Request': async ({ commit }, { form }) => {
    const res = await http.post('web-api/Register+Request', form);

    commit('commission/Push', res.data.data, ROOT);

    return res.data.data;
  },

  async LoginAfterRegistration({ dispatch, getters }, { email, password }) {
    const attempt = await dispatch('StartAttempt', {
      form: {
        identifier: email,
        identifier_type: 'email',
        device_token: getters.DeviceToken,
        purpose: 'Login',
      },
    });

    if (!attempt) return null;

    await sleep(1250);

    const res = await dispatch('VerifyPassword', {
      form: {
        attempt_id: attempt.id,
        attempt_secret: attempt.secret,
        password,
      },
    });

    if (!res) return null;

    return attempt;
  },

  async StartAttempt(context, { form }) {
    const res = await http.post('auth/attempt', form);

    return res.data.data;
  },

  async VerifyPassword(context, { form }) {
    const res = await http.post('auth/attempt/password', form);

    return res.data.data;
  },

  async VerifyPublicKeyCredential(context, { form, query }) {
    const res = await http.post('auth/attempt/public_key_credential', form, {
      params: query,
    });

    return res.data.data;
  },

  async VerifyTotp(context, { form }) {
    const res = await http.post('auth/attempt/totp', form);

    return res.data.data;
  },

  async VerifyTotpRecoverySecret(context, { form }) {
    const res = await http.post('auth/attempt/totp_recovery_secret', form);

    return res.data.data;
  },

  async SendEmailLink(context, { form }) {
    const res = await http.post('auth/attempt/email_link/send', form);

    return res.data.data;
  },

  async VerifyEmailLinkSecret(context, { form }) {
    const res = await http.post('auth/attempt/email_link', form);

    return res.data.data;
  },

  async IssueDeviceToken({ commit }, { form }) {
    const res = await http.post('auth/attempt/device_token', form);

    commit('DeviceToken', res.data.data.token);

    return res.data.data;
  },
};

export default actions;
