import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';

const getters: GetterTree<State.Business, State.Root> = {
  Detect: (s) => (idOrVanity: string): ApiResource.Business | undefined => {
    const VANITY = s.list.filter((b) => b.vanity === idOrVanity)[0];
    const ID = s.list.filter((b) => b.id === idOrVanity)[0];
    return VANITY || ID;
  },
  Find: (s) => (id: string): ApiResource.Business => s.list
    .filter((b) => b.id === id)[0],
  FindAccount: (s) => (b: string): ApiResource.Business.Account => s.accounts
    .filter((a) => a.business_id === b)[0],
  FindBalances: (s) => (a: string): ApiResource.Business.Balances => s.balances
    .filter((b) => b.id === a)[0],
  FindByVanity: (s) => (v: string): ApiResource.Business => s.list
    .filter((b) => b.vanity === v)[0],
  List: (s) => s.list,
};

export default getters;
