/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';

function push(arr: ApiResource.Vanity[], obj: ApiResource.Vanity): void {
  const i = arr.indexOf(
    arr.filter((v) => v.vanity === obj.vanity)[0],
  );

  if (i === -1) arr.push(obj);
  else arr.splice(i, 1, obj);
}

const mutations: MutationTree<State.Vanity> = {
  Push(state, vanity: ApiResource.Vanity) {
    push(state.list, vanity);
  },
};

export default mutations;
