




































import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import AnalyticsPageviewObserver from '@/components/analytics/PageviewObserver.vue';
import AppLocale from '@/components/app/Locale.vue';
import AppHeader from '@/components/app/Header.vue';
import AppOffline from '@/components/app/Offline.vue';
import AppRefreshToken from '@/components/app/RefreshToken.vue';
import AppSentry from '@/components/app/Sentry.vue';
import AppVersionChaperone from '@/components/app/VersionChaperone.vue';
import StripeJS from '@/components/app/Stripe.js.vue';
import { LOCALES } from '@/i18n';
import { MetaInfo } from 'vue-meta';
import { getResolvableLocaleLocation } from './router';

function getAlternateLocales(current: string): any[] {
  const alternate = LOCALES.filter((l) => l !== current);
  const metaTags: any[] = [];

  alternate.forEach((l) => {
    metaTags.push({
      vmID: `og:locale:alternate:${l}`,
      property: 'og:locale:alternate',
      content: l,
    });
  });

  return metaTags;
}

// To get load event timings, send after load event
function callbackAfterLoad(cb: CallableFunction): void {
  if (window.document.readyState === 'complete') cb();
  else window.addEventListener('load', () => setTimeout(cb));
}

interface Bindings {
  getLatestBuildInfo: () => void,
  dispatchInitialBeacon: () => void,
}

export default (Vue as VueConstructor<Vue & Bindings>).extend({
  components: {
    AnalyticsPageviewObserver,
    ApiDown: () => import(/* webpackChunkName: "app-status" */'@/components/app/ApiDown.vue'),
    ApiMaintenance: () => import(/* webpackChunkName: "app-status" */'@/components/app/ApiMaintenance.vue'),
    ApiReadonly: () => import(/* webpackChunkName: "app-status" */'@/components/app/ApiReadonly.vue'),
    AppFeedback: () => import(/* webpackChunkName: "app-fb" */'@/components/app/Feedback.vue'),
    AppFooter: () => import(/* webpackChunkName: "app-footer" */'@/components/app/Footer.vue'),
    AppHeader,
    AppLocale,
    AppOffline,
    AppRealtime: () => import(/* webpackChunkName: "realtime" */ '@/components/app/Realtime.vue'),
    AppRealtimePubNub: () => import(/* webpackChunkName: "realtime-pubnub" */ '@/components/app/RealtimePubNub.vue'),
    AppRefreshToken,
    AppSentry,
    AppVersionChaperone, // Not async as it might be changed and then fail to load!
    StripeJS,
  },
  computed: {
    ...mapGetters({
      ab: 'AB_SEGMENT',
      isAuthenticated: 'account/IsLoggedIn',
      isDown: 'IS_API_DOWN',
      isMaintenance: 'IS_API_MAINTENANCE',
      isReadonly: 'IS_API_READONLY',
      isOnline: 'IS_ONLINE',
      isOutdated: 'IS_BUILD_OUTDATED',
      isPrerendering: 'IS_PRERENDERING',
    }),
  },
  created(): void {
    if (this.isPrerendering) return;

    this.initialize();
  },
  methods: {
    initialize(): void {
      this.setAbSegment();
      this.dispatchInfoAction();
      this.setBuildInfoInterval();
      this.addEventListeners();
    },
    setAbSegment(): void {
      if (this.$store.getters.AB_SEGMENT) return;
      this.$store.commit('SetAbSegment', Math.ceil(Math.random() * 1000));
    },
    addEventListeners(): void {
      window.addEventListener('online', () => this.$store.commit('SetOnline', true));
      window.addEventListener('offline', () => this.$store.commit('SetOnline', false));
      window.addEventListener('focus', this.getLatestBuildInfo);
    },
    setBuildInfoInterval(): void {
      window.setInterval(this.getLatestBuildInfo, 60 * 60 * 1000);
    },
    dispatchInfoAction(): void {
      this.$store.dispatch('info')
        .then(() => callbackAfterLoad(this.dispatchInitialBeacon));
    },
    dispatchInitialBeacon(): void {
      this.$store.dispatch('SendBeacon', {
        action: '0',
        route: this.$router.currentRoute,
      });
    },
    getLatestBuildInfo(): void {
      this.$store.dispatch('info');
    },
  },
  meta(): MetaInfo {
    const title = this.$t('title');
    const description = this.$t('description');

    const linkTags: any[] = [];

    LOCALES.forEach((locale) => {
      linkTags.push({
        vmID: `alternate:hreflang:${locale}`,
        rel: 'alternate',
        hreflang: locale,
        href: `${process.env.VUE_APP_BASE_URL}${this.$router.resolve(getResolvableLocaleLocation(this.$router, this.$route, locale)).href}`,
      });
    });

    linkTags.push({
      vmID: 'alternate:hreflang:x-default',
      rel: 'alternate',
      hreflang: 'x-default',
      href: `${process.env.VUE_APP_BASE_URL}${this.$router.resolve(getResolvableLocaleLocation(this.$router, this.$route, 'en')).href}`,
    });

    return {
      afterNavigation() {
        document.dispatchEvent(new Event('vue-meta-after-navigation'));
      },
      htmlAttrs: {
        // Schema.org
        // itemscope: [],
        // itemtype: 'https://schema.org/',
        lang: this.$root.$i18n.locale,
        prefix: 'og: http://ogp.me/ns#',
        'ab-segment': this.ab,
        // ^ 0 will not be added as attr unless .toString()'ed!
      },
      link: [
        ...linkTags,
        // {
        //   vmID: 'oembed:json',
        //   rel: 'alternate',
        //   type: 'application/json+oembed',
        //   href: 'https://api.auftrag.app/oembed.json?url='+this.$route.path,
        //   title: 'oEmbed Profile: JSON',
        // },
        // {
        //   vmID: 'oembed:xml',
        //   rel: 'alternate',
        //   type: 'application/xml+oembed',
        //   href: 'https://api.auftrag.app/oembed.xml?url='+this.$route.path,
        //   title: 'oEmbed Profile: XML',
        // },
      ],
      meta: [
        {
          vmID: 'description',
          name: 'description',
          content: description,
        },
        // OpenGraph
        // Static: 'og:site_name'
        {
          vmID: 'og:title',
          property: 'og:title',
          content: title,
        },
        {
          vmID: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          vmID: 'og:image',
          property: 'og:image',
          content: '/favicon.png',
        },
        {
          vmID: 'og:url',
          property: 'og:url',
          content: `${process.env.VUE_APP_BASE_URL}${this.$route.fullPath}`,
        },
        {
          vmID: 'og:description',
          property: 'og:description',
          content: description,
        },
        {
          vmID: 'og:locale',
          property: 'og:locale',
          content: this.$root.$i18n.locale,
        },
        ...getAlternateLocales(this.$root.$i18n.locale),
        // Twitter Cards
        {
          vmID: 'twitter:card',
          name: 'twitter:card',
          content: 'summary',
        },
        {
          vmID: 'twitter:title',
          name: 'twitter:title',
          content: title,
        },
        {
          vmID: 'twitter:url',
          name: 'og:url',
          content: `${process.env.VUE_APP_BASE_URL}${this.$route.fullPath}`,
        },
        {
          vmID: 'twitter:description',
          name: 'twitter:description',
          content: description,
        },
        // Schema.org
        // {
        //   vmID: 'schema:name',
        //   itemprop: 'name',
        //   content: 'Content Title',
        // },
        // Pinterest: No-pin
        // {
        //   vmID: 'pinterest:nopin',
        //   name: 'pinterset',
        //   content: 'nopin',
        //   // description: 'Sorry…', // Optional
        // },
      ],
    };
  },
});
