import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Profile, State.Root> = {
  async Find({ commit }, { PROFILE_ID }) {
    const res = await Api().get(`profiles/${PROFILE_ID}`);

    commit('Push', res.data);
    commit('pages/RemoveByBusinessId', res.data.data.id);
    commit('pages/PushList', res.data.data.pages);

    return res.data;
  },

  async FindByVanity({ commit }, vanity) {
    const res = await Api().get('profiles', {
      params: { vanity: vanity.toLowerCase() },
    });

    commit('Push', res.data);
    commit('pages/RemoveByBusinessId', res.data.data.id);
    commit('pages/PushList', res.data.data.pages);

    return res.data;
  },

  async ListCommissioned({ commit }, query) {
    const res = await Api().get('commission_businesses', { params: query });

    console.debug(res.data);

    commit('PushList', res.data);
    commit('PREVIOUS_PUSH_LIST', res.data);

    return res.data;
  },

  async Search({ commit }, search) {
    const res = await Api().get('profile_search', {
      params: { name: search },
    });

    commit('PushList', res.data);

    return res.data;
  },

  async Watchlist({ commit }, { query }) {
    const res = await Api().get('watchlist', {
      params: query,
    });

    commit('PushList', res.data);
    commit('WATCHLIST_PUSH_LIST', res.data);

    return res.data;
  },

  async WatchlistAdd({ commit }, { BUSINESS_ID }) {
    const res = await Api().put(`/watchlist/${BUSINESS_ID}`);

    commit('WATCHLIST_PUSH', res.data);
  },

  async WatchlistRemove({ commit }, { BUSINESS_ID }) {
    commit('WATCHLIST_REMOVE', BUSINESS_ID);

    await Api().delete(`/watchlist/${BUSINESS_ID}`);
  },
};

export default actions;
