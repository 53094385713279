/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/no-cycle */
import { RouteConfig } from 'vue-router';
import { registerBusinessSubmodules, registerBusinessCommissionSubmodules, registerUploadSubmodule } from '@/store';

const routes: RouteConfig[] = [
  {
    path: '/dashboard',
    redirect: { name: 'dashboard', params: { VANITY: '0' } },
  },
  {
    path: '/dashboard/:VANITY',
    component: () => import(/* webpackChunkName: "v-d-root" */ '@/views/dashboard/Root.vue'),
    meta: {
      requireAuth: true,
    },
    props: (route) => ({ vanity: route.params.VANITY }),
    async beforeEnter(to, from, next) {
      registerBusinessSubmodules().then(() => next());
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        components: {
          default: () => import(/* webpackChunkName: "v-d-root" */ '@/views/dashboard/Index.vue'),
          menu: () => import(/* webpackChunkName: "v-d-menu" */ '@/views/dashboard/Menu.vue'),
        },
      },
      {
        path: 'commissions',
        name: 'dashboard.commissions',
        components: {
          default: () => import(/* webpackChunkName: "v-d-cq" */ '@/views/dashboard/commissions/Root.vue'),
          menu: () => import(/* webpackChunkName: "v-d-cq" */ '@/views/dashboard/commissions/Menu.vue'),
        },
      },
      {
        path: 'profile',
        components: {
          default: () => import(/* webpackChunkName: "v-d-pf" */ '@/views/dashboard/profile/Root.vue'),
          menu: () => import(/* webpackChunkName: "v-d-pf" */ '@/views/dashboard/profile/Menu.vue'),
        },
        children: [
          {
            path: '',
            name: 'dashboard.profile',
            redirect: { name: 'dashboard.appearance' },
          },
          {
            path: 'vanity',
            name: 'dashboard.vanity',
            component: () => import(/* webpackChunkName: "v-d-url" */ '@/views/dashboard/profile/Vanity.vue'),
          },
          {
            path: 'appearance',
            name: 'dashboard.appearance',
            component: () => import(/* webpackChunkName: "v-d-edit" */ '@/views/dashboard/profile/Appearance.vue'),
          },
          {
            path: 'images',
            name: 'dashboard.images',
            component: () => import(/* webpackChunkName: "v-d-img" */ '@/views/dashboard/profile/Images.vue'),
          },
          {
            path: 'pages',
            name: 'dashboard.pages',
            component: () => import(/* webpackChunkName: "v-d-pl" */ '@/views/dashboard/profile/Pages.vue'),
            props: true,
          },
          {
            path: 'pages/new',
            name: 'dashboard.new-page',
            component: () => import(/* webpackChunkName: "v-d-p" */ '@/views/dashboard/profile/Page.vue'),
            props: true,
          },
          {
            path: 'pages/:pageId',
            name: 'dashboard.page',
            component: () => import(/* webpackChunkName: "v-d-p" */ '@/views/dashboard/profile/Page.vue'),
            props: true,
          },
        ],
      },
      {
        path: 'settings',
        components: {
          default: () => import(/* webpackChunkName: "v-d-f-root" */ '@/views/dashboard/settings/Root.vue'),
          menu: () => import(/* webpackChunkName: "v-d-f-root" */ '@/views/dashboard/settings/Menu.vue'),
        },
        children: [
          {
            path: '',
            name: 'dashboard.settings',
            redirect: { name: 'dashboard.pricing' },
          },
          {
            path: 'quotas',
            name: 'dashboard.limits',
            component: () => import(/* webpackChunkName: "v-d-lim" */ '@/views/dashboard/settings/Limits.vue'),
          },
          {
            path: 'storage',
            name: 'dashboard.storage',
            redirect: { name: 'dashboard.requests', hash: '#default-storage-zone' },
          },
          {
            path: 'search-settings',
            name: 'dashboard.search-settings',
            redirect: { name: 'dashboard.requests', hash: '#maker-search-visibility' },
          },
          {
            path: 'pricing',
            name: 'dashboard.pricing',
            component: () => import(/* webpackChunkName: "v-d-pm" */ '@/views/dashboard/settings/Pricing.vue'),
          },
          {
            path: 'requests',
            name: 'dashboard.requests',
            component: () => import(/* webpackChunkName: "v-d-rq" */ '@/views/dashboard/settings/Requests.vue'),
          },
        ],
      },
      {
        path: 'earnings',
        components: {
          default: () => import(/* webpackChunkName: "v-d-e" */ '@/views/dashboard/earnings/Root.vue'),
          menu: () => import(/* webpackChunkName: "v-d-e" */ '@/views/dashboard/earnings/Menu.vue'),
        },
        children: [
          {
            path: '',
            name: 'dashboard.earnings',
            redirect: { name: 'dashboard.balances' },
          },
          {
            path: 'connect',
            name: 'dashboard.connect',
            component: () => import(/* webpackChunkName: "v-d-con" */ '@/views/dashboard/earnings/Connect.vue'),
          },
          {
            path: 'balances',
            name: 'dashboard.balances',
            component: () => import(/* webpackChunkName: "v-d-bal" */ '@/views/dashboard/earnings/Balances.vue'),
          },
          {
            path: 'fees',
            name: 'dashboard.fees',
            component: () => import(/* webpackChunkName: "v-d-fee" */ '@/views/dashboard/earnings/Fees.vue'),
          },
          {
            path: 'payments',
            name: 'dashboard.payments',
            component: () => import(/* webpackChunkName: "v-d-py" */ '@/views/dashboard/earnings/Payments.vue'),
          },
          {
            path: 'payouts',
            name: 'dashboard.payouts',
            component: () => import(/* webpackChunkName: "v-d-po" */ '@/views/dashboard/earnings/Payouts.vue'),
          },
          {
            path: 'schedule',
            name: 'dashboard.schedule',
            component: () => import(/* webpackChunkName: "v-d-ps" */ '@/views/dashboard/earnings/Schedule.vue'),
          },
          {
            path: 'accounts',
            name: 'dashboard.external-accounts',
            component: () => import(/* webpackChunkName: "v-d-ex" */ '@/views/dashboard/earnings/ExternalAccounts.vue'),
          },
          {
            path: 'verification',
            name: 'dashboard.verification',
            component: () => import(/* webpackChunkName: "v-d-vf" */ '@/views/dashboard/earnings/Verification.vue'),
          },
          {
            path: 'vouchers',
            name: 'dashboard.vouchers',
            component: () => import(/* webpackChunkName: "v-d-iv" */ '@/views/dashboard/earnings/Vouchers.vue'),
          },
        ],
      },
      {
        path: 'auftrag/:COMMISSION_ID',
        components: {
          default: () => import(/* webpackChunkName: "v-d-c-root" */ '@/views/dashboard/commission/Root.vue'),
          menu: () => import(/* webpackChunkName: "v-d-c-menu" */ '@/views/dashboard/commission/Menu.vue'),
        },
        beforeEnter(to, from, next) {
          registerBusinessCommissionSubmodules().then(() => next());
        },
        children: [
          {
            path: '',
            name: 'dashboard.commission',
            component: () => import(/* webpackChunkName: "v-d-c-root" */ '@/views/dashboard/commission/Index.vue'),
          },
          {
            path: 'activity',
            name: 'dashboard.commission.events',
            component: () => import(/* webpackChunkName: "v-d-c-ev" */ '@/views/dashboard/commission/Events.vue'),
          },
          {
            path: 'messages',
            name: 'dashboard.commission.messages',
            component: () => import(/* webpackChunkName: "v-d-c-chat" */ '@/views/dashboard/commission/Messages.vue'),
          },
          {
            path: 'files',
            name: 'dashboard.commission.files',
            component: () => import(/* webpackChunkName: "v-d-c-f" */ '@/views/dashboard/commission/Files.vue'),
          },
          {
            path: 'upload',
            name: 'dashboard.commission.upload',
            beforeEnter(to, from, next) {
              registerUploadSubmodule().then(() => next());
            },
            component: () => import(/* webpackChunkName: "v-d-c-up" */ '@/views/dashboard/commission/Upload.vue'),
          },
          {
            path: 'files/:FILE_ID',
            name: 'dashboard.commission.file',
            component: () => import(/* webpackChunkName: "v-d-c-f" */ '@/views/dashboard/commission/File.vue'),
          },
          {
            path: 'items',
            name: 'dashboard.commission.items',
            component: () => import(/* webpackChunkName: "v-d-c-i" */ '@/views/dashboard/commission/Items.vue'),
          },
          {
            path: 'create-item',
            name: 'dashboard.commission.create-item',
            component: () => import(/* webpackChunkName: "v-d-c-ci" */ '@/views/dashboard/commission/CreateItem.vue'),
          },
          {
            path: 'items/:ITEM_ID',
            name: 'dashboard.commission.item',
            component: () => import(/* webpackChunkName: "v-d-c-i" */ '@/views/dashboard/commission/Item.vue'),
          },
          {
            path: 'trackers',
            name: 'dashboard.commission.trackers',
            component: () => import(/* webpackChunkName: "v-d-c-t" */ '@/views/dashboard/commission/Trackers.vue'),
          },
          {
            path: 'track-shipment',
            name: 'dashboard.commission.add-tracker',
            component: () => import(/* webpackChunkName: "v-d-c-ct" */ '@/views/dashboard/commission/AddTracker.vue'),
          },
        ],
      },
      {
        path: 'welcome',
        components: {
          default: () => import(/* webpackChunkName: "v-d-w-root" */ '@/views/dashboard/welcome/Root.vue'),
          menu: () => import(/* webpackChunkName: "v-d-w-root" */ '@/views/dashboard/welcome/Menu.vue'),
        },
        children: [
          {
            path: '',
            name: 'dashboard.welcome',
            component: () => import(/* webpackChunkName: "v-d-w-root" */ '@/views/dashboard/welcome/Index.vue'),
          },
          {
            path: 'step-1',
            name: 'dashboard.welcome.step-1',
            component: () => import(/* webpackChunkName: "v-d-w-step" */ '@/views/dashboard/welcome/Step1.vue'),
          },
          {
            path: 'step-2',
            name: 'dashboard.welcome.step-2',
            component: () => import(/* webpackChunkName: "v-d-w-step" */ '@/views/dashboard/welcome/Step2.vue'),
          },
          {
            path: 'step-3',
            name: 'dashboard.welcome.step-3',
            component: () => import(/* webpackChunkName: "v-d-w-step" */ '@/views/dashboard/welcome/Step3.vue'),
          },
        ],
      },
    ],
  },
];

export default routes;
