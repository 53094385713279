/* eslint-disable camelcase */
import { NumberFormats } from 'vue-i18n';

const en_USD = {
  currency: 'USD',
  style: 'currency',
  currencyDisplay: 'code',
};

const en_EUR = {
  currency: 'EUR',
  style: 'currency',
};

const de_USD = {
  currency: 'USD',
  style: 'currency',
};

const de_EUR = {
  currency: 'EUR',
  style: 'currency',
};

// https://kazupon.github.io/vue-i18n/guide/number.html
// $n(1, 'format', 'locale')
const numberFormats: NumberFormats = {
  en: {
    EUR: en_EUR,
    eur: en_EUR,
    USD: en_USD,
    usd: en_USD,
  },
  de: {
    EUR: de_EUR,
    eur: de_EUR,
    USD: de_USD,
    usd: de_USD,
  },
};

export default numberFormats;
