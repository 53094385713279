/* eslint-disable no-param-reassign */
import {
  formatDistance, formatDistanceStrict, formatRelative, Locale,
} from 'date-fns';
import { VueConstructor } from 'vue';
import { I18nLocale } from '@/types';
import VueI18n from 'vue-i18n';
// import DateDistanceComponent from './DateDistance.vue';

type DateFnsLocales = Record<I18nLocale, Locale>;

function localizedFormatDistance(
  locales: DateFnsLocales,
  i18n: VueI18n,
): (
    date: Date,
    base?: Date,
    addSuffix?: boolean,
    includeSeconds?: boolean,
  ) => string {
  return (
    date: Date,
    base: Date = new Date(),
    addSuffix = false,
    includeSeconds = false,
  ): string => {
    const locale = locales[i18n.locale as I18nLocale];

    return formatDistance(date, base, {
      includeSeconds, addSuffix, locale,
    });
  };
}

interface FormatDistanceStrictOptions {
  unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year',
  roundingMethod?: 'floor' | 'ceil' | 'round',
}

function localizedFormatDistanceStrict(
  locales: DateFnsLocales,
  i18n: VueI18n,
): (
    date: Date,
    base?: Date,
    addSuffix?: boolean,
    options?: FormatDistanceStrictOptions,
  ) => string {
  return (date: Date, base: Date = new Date(), addSuffix = false, options = {}): string => {
    const locale = locales[i18n.locale as I18nLocale];

    return formatDistanceStrict(date, base, {
      ...options, addSuffix, locale,
    });
  };
}

function localizedFormatRelative(
  locales: DateFnsLocales,
  i18n: VueI18n,
): (
    date: Date,
    base?: Date,
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6,
  ) => string {
  return (
    date: Date,
    base: Date = new Date(),
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6,
  ): string => {
    const locale = locales[i18n.locale as I18nLocale];
    let w = weekStartsOn;

    if (w === undefined && i18n.locale === 'de') w = 1;
    if (w === undefined && i18n.locale === 'en') w = 0;

    return formatRelative(date, base, {
      weekStartsOn: w,
      locale,
    });
  };
}

export default {
  install(
    Vue: VueConstructor,
    { locales, i18n }: { locales: DateFnsLocales, i18n: VueI18n },
  ): void {
    Vue.prototype.$dd = localizedFormatDistance(locales, i18n);
    Vue.prototype.$dds = localizedFormatDistanceStrict(locales, i18n);
    Vue.prototype.$dr = localizedFormatRelative(locales, i18n);
  },
};

declare module 'vue/types/vue' {
  interface Vue {
    $dd: (date: Date, base: Date, addSuffix?: boolean, includeSeconds?: boolean) => string,
    $dds: (
      date: Date,
      base: Date,
      addSuffix?: boolean,
      options?: FormatDistanceStrictOptions,
    ) => string,
    $dr: (date: Date, base: Date, weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6) => string,
  }
}
