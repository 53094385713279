/* eslint-disable camelcase */
export type Resource = {
  id: string,
}

type CommissionResource = Resource & {
  commission_id: string,
}

// Find a resource by ID
/**
 * @return ?T
 */
export function findResource<T extends Resource>(arr: T[], id: string): T {
  return arr.filter((res) => res.id === id)[0];
}

export function findCommissionResource<T extends CommissionResource>(
  arr: T[], cid: string, id: string,
): T | undefined {
  return arr
    .filter((res) => res.commission_id === cid)
    .filter((res) => res.id === id)[0];
}

// Push resource to list or update in place
export function pushResource<T extends Resource>(arr: T[], obj: T): void {
  const i = arr.indexOf(findResource<T>(arr, obj.id));

  if (i === -1) arr.push(obj); // Push object
  else arr.splice(i, 1, obj); // Replace object in place
}

export function push<T>(arr: T[], item: T): void {
  const i = arr.indexOf(item);

  if (i === -1) arr.push(item);
  else arr.splice(i, 1, item);
}

export function remove<T>(arr: T[], item: T): void {
  arr.splice(arr.indexOf(item), 1);
}

// Remove resource from list
export function removeResource<T extends Resource>(arr: T[], obj: T): void {
  arr.splice(arr.indexOf(obj), 1);
}

export function removeResourceById<T extends Resource>(arr: T[], id: string): void {
  const obj = findResource(arr, id);
  if (obj) removeResource(arr, obj);
}
