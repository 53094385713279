






import Vue from 'vue';

export default Vue.extend({
  name: 'BaseLinkPhone',
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
  computed: {
    tel(): string {
      // Remove (, ), -, white spaces
      const num = this.phone.replace(/[()-\s]/g, '');

      return `tel:${num}`;
    },
  },
});
