import { ActionTree } from 'vuex';
import Axios from 'axios';
import { State } from '@/types';

const ROOT = { root: true };

const http = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  responseType: 'json',
  maxRedirects: 0,
  withCredentials: false,
});

const actions: ActionTree<State.OAuth2, State.Root> = {
  // $store.dispatch('oauth2/password', {token, id})
  async password({ dispatch }, { username, password }) {
    try {
      const res = await http.post('oauth2/token', {
        grant_type: 'password',
        client_id: process.env.VUE_APP_OAUTH2_CLIENT_ID,
        client_secret: null,
        scope: ['*'],
        username,
        password,
      });

      return dispatch('auth/login', res.data, ROOT);

      // commit('tokens', res.data);

      // return dispatch('account/GetAccount');
    } catch (e) {
      dispatch('auth/logout', undefined, ROOT);

      throw e;
    }
  },
  async refresh({ commit, dispatch, getters }) {
    try {
      const res = await http.post('oauth2/token', {
        grant_type: 'refresh_token',
        client_id: process.env.VUE_APP_OAUTH2_CLIENT_ID,
        client_secret: null,
        scope: ['*'],
        refresh_token: getters.RefreshToken,
      });

      return commit('tokens', res.data);
    } catch (e) {
      dispatch('auth/logout', undefined, ROOT);

      throw e;
    }
  },
};

export default actions;
