/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { push, pushResource, remove } from '@/util/list';

const mutations: MutationTree<State.Profile> = {
  Push(state, { data }: { data: ApiResource.Profile }) {
    pushResource(state.list, data);
  },
  PushList(state, { data }: { data: ApiResource.Profile[] }) {
    data.forEach((res) => pushResource(state.list, res));
  },
  PREVIOUS_PUSH_LIST(state, { data }: { data: ApiResource.Profile[] }) {
    data.forEach((res) => push(state.previouslyCommissioned, res.id));
  },
  WATCHLIST_PUSH(state, { data }: { data: ApiResource.Profile }) {
    push(state.watched, data.id);
  },
  WATCHLIST_PUSH_LIST(state, { data }: { data: ApiResource.Profile[] }) {
    data.forEach((res) => push(state.watched, res.id));
  },
  WATCHLIST_REMOVE(state, id: string) {
    remove(state.watched, id);
  },
};

export default mutations;
