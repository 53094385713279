









import Vue from 'vue';

export default Vue.extend({
  name: 'BaseTile',
  props: {
    tag: {
      type: String,
      required: false,
      default: 'div',
    },
  },
});
