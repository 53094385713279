

































import Vue from 'vue';
import { I18nLocale } from '@/types';
import { getResolvableLocaleLocation } from '@/router';

export default Vue.extend({
  props: {
    listLayout: {
      type: String,
      required: false,
      default: 'ul',
    },
  },
  data() {
    return {
      locales: [
        'en',
        'de',
      ] as I18nLocale[],
    };
  },
  computed: {
    currentLocale() {
      return this.$root.$i18n.locale;
    },
  },
  methods: {
    getResolvedRawLocation(locale: I18nLocale) {
      return getResolvableLocaleLocation(this.$router, this.$route, locale);
    },
  },
});
