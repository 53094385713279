<template>
  <progress
    ref="progress"
    class="progress"
    v-on="$listeners"
  >
    <slot name="default" />
  </progress>
</template>

<script lang="js">
export default {
  name: 'BaseProgress',
  watch: {
    '$attrs.value': {
      immediate: false,
      handler: (to) => {
        if (to === null) {
          console.debug('<BaseProgress>', 'Removing value attribute...');
          // Must be delayed, as value is set to 0 during this tick
          this.$nextTick(() => {
            this.$refs.progress.removeAttribute('value');
          });
        }
      },
    },
  },
};
</script>

<style lang="sass" scoped>
@import "@/styles/_bulma-customization.sass"
@import "~bulma/sass/elements/progress"
</style>
