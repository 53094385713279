
import { isValidLocale, normalizeLocale } from '@/i18n';
import { I18nLocale } from '@/types';
import Vue from 'vue';

export default Vue.extend({
  watch: {
    '$root.$i18n.locale': {
      immediate: false,
      handler(nextLocale: I18nLocale): void {
        console.debug('[I18n] Locale changed:', nextLocale);
        window.localStorage.setItem('locale', nextLocale);
        this.$sentry.setTag('locale', nextLocale);

        if (!this.$route.meta.locale) return;
        if (nextLocale === this.$route.meta.locale) return;

        const [base] = (this.$route.name || '').split('#');

        const { query } = this.$route;
        delete query.locale;

        this.$router.replace({
          ...this.$route,
          name: `${base}#${nextLocale}`,
          query,
        });
      },
    },
    '$route.query.locale': {
      immediate: true,
      /**
       * Update application locale when ?locale= changes.
       */
      handler(to): void {
        let locale: string | null = null;

        // eslint-disable-next-line prefer-destructuring
        if (Array.isArray(to)) locale = to[0];
        else locale = to;

        locale = normalizeLocale(locale);

        if (!isValidLocale(locale)) return;

        this.setLocale(locale);
      },
    },
    '$route.meta.locale': {
      immediate: false,
      handler(to) {
        const locale = normalizeLocale(to);

        if (!isValidLocale(locale)) return;

        this.setLocale(locale);
      },
    },
  },
  methods: {
    setLocale(locale: I18nLocale): void {
      this.$root.$i18n.locale = locale;
    },
  },
  render: (h) => h(),
});
