
















































import Vue from 'vue';

export default Vue.extend({
  name: 'BaseModal',
  props: {
    isActive: Boolean,
    isCard: Boolean,
    hasCardHead: Boolean,
    hasCardFoot: Boolean,
  },
  methods: {
    close(): void {
      this.$emit('modal-close', true);
    },
  },
});
