/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { State } from '@/types';

const mutations: MutationTree<State.Realtime> = {
  Event(state, payload: any) {
    state.events.push(payload);
  },
};

export default mutations;
