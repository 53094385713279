










import Vue from 'vue';

export default Vue.extend({
  name: 'BaseLinkExternal',
  props: {
    noFollow: Boolean,
    isUgc: Boolean,
    isHelp: Boolean,
  },
  computed: {
    relAttribute(): string {
      let rel = 'external noopener noreferrer';

      if (this.noFollow) rel = rel.concat(' nofollow');
      if (this.isHelp) rel = rel.concat(' help');
      if (this.isUgc) rel = rel.concat(' ugc');

      return rel;
    },
  },
});
