import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';

const getters: GetterTree<State.Profile.Pages, State.Root> = {
  Find: (s) => (id: string): ApiResource.Profile.Page | undefined => s.list
    .filter((p) => p.id === id)[0],
  Slug: (s) => (bid: string, slug: string): ApiResource.Profile.Page | undefined => s.list
    .filter((p) => p.business_id === bid)
    .filter((p) => p.slug === slug)[0],
  List: (s) => (bid: string): ApiResource.Profile.Page[] => s.list
    .filter((p) => p.business_id === bid),
};

export default getters;
