/* eslint-disable global-require */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { I18nLocale } from '@/types';
import dateTimeFormats from '@/i18n/dateTimeFormats';
import numberFormats from '@/i18n/numberFormats';

export function isValidLocale(locale: any): locale is I18nLocale {
  return locale === 'de'
    || locale === 'en';
}

Vue.use(VueI18n);

// @see https://github.com/antfu/i18n-ally/wiki/Path-Matcher
// i18n/{locale}/{namespaces}.{ext}

const en = {
  common: require('@/i18n/en/common.yaml'),
  country: require('@/i18n/en/country.yaml'),
  currency: require('@/i18n/en/currency.yaml'),
  realtime: require('@/i18n/en/realtime.yaml'),
  locale: require('@/i18n/en/locale.yaml'),
  v: require('@/i18n/en/v.yaml'),
  zone: require('@/i18n/en/zone.yaml'),
};

const de = {
  common: require('@/i18n/de/common.yaml'),
  locale: require('@/i18n/de/locale.yaml'),
  zone: require('@/i18n/de/zone.yaml'),
};

export function normalizeLocale(locale: string | null): string | null {
  if (!locale) return locale;
  if (locale.startsWith('de-')) return 'de';
  if (locale.startsWith('en-')) return 'en';
  return locale;
}

function determineDefaultLocale(): I18nLocale {
  const storedLocale = normalizeLocale(window.localStorage.getItem('locale'));

  if (isValidLocale(storedLocale)) return storedLocale;

  const browserLocales = window.navigator.languages;

  for (let index = 0; index < browserLocales.length; index += 1) {
    const browserLocale = normalizeLocale(browserLocales[index]);

    if (isValidLocale(browserLocale)) return browserLocale;
  }

  return 'en';
}

const i18n = new VueI18n({
  dateTimeFormats,
  locale: determineDefaultLocale(),
  fallbackLocale: ['en', 'de', 'en'],
  messages: {
    en,
    de,
  },
  numberFormats,
});

export default i18n;

export const LOCALES: I18nLocale[] = [
  'en',
  'de',
];
