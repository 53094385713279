



























































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import SvgLogo from '@/components/svg/Logo.vue';
import I18nSelectLocale from '@/components/i18n/SelectLocaleList.vue';

export default Vue.extend({
  components: {
    SvgLogo,
    I18nSelectLocale,
    TheDownloadProgress: () => import(/* webpackChunkName: "download" */ '@/components/app/header/DownloadProgress.vue'),
    TheUploadProgress: () => import(/* webpackChunkName: "upload" */ '@/components/app/header/UploadProgress.vue'),
  },
  data() {
    return {
      isAccountActive: false,
      isMenuActive: false,
      isLocaleActive: false,
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/Account',
      businesses: 'business/List',
      isAuthenticated: 'account/IsLoggedIn',
    }),
  },
});
