import { ActionTree } from 'vuex';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const ROOT = { root: true };

const actions: ActionTree<State.Account, State.Root> = {
  async GetAccount({ commit }) {
    try {
      const res = await Api().get('account');

      commit('SetAccount', res.data.data);
      commit('SetAbSegment', res.data.data.ab_segment, ROOT);

      return res.data;
    } catch (e) {
      commit('UnsetAccount');

      throw e;
    }
  },

  async PatchAccount({ commit }, payload) {
    const res = await Api().patch('account', payload.form);

    commit('SetAccount', res.data.data);
    commit('SetAbSegment', res.data.data.ab_segment, ROOT);

    return res.data;
  },

  async ChangeIcon(context, payload) {
    const res = await Api().patch('icon', payload.form);

    return res.data;
  },

  async ChangePassword(context, { form, TOKEN }) {
    const res = await Api(TOKEN).patch('password', form);

    return res.data;
  },

  async CreateBusiness(context, payload) {
    const res = await Api().post('businesses', payload.form);

    context.commit('business/Push', res.data, ROOT);

    return res.data;
  },

  async ListBusinesses({ commit }, { query } = {}) {
    const res = await Api().get('businesses', {
      params: query,
    });

    commit('business/PushList', res.data, ROOT);

    return res.data;
  },

  async CreateCommission(context, { form }) {
    const res = await Api().post('commissions', form);

    context.commit('commission/Push', res.data.data, ROOT);

    return res.data;
  },

  async ListCommissions({ commit }, { query } = { limit: 10 }) {
    const res = await Api().get('commissions', {
      params: query,
    });

    commit('commission/PushList', res.data, ROOT);

    return res.data;
  },

  async ListCommissionEvents({ commit }, { query } = { page: 1, perPage: 20 }) {
    const res = await Api().get('commission_events', {
      params: query,
    });

    commit('PushCommissionEventList', res.data);

    return res.data;
  },

  async ListContextEvents({ commit }, { query } = { page: 1, perPage: 20 }) {
    const res = await Api().get('events', {
      params: query,
    });

    commit('PushContextEventList', res.data);

    return res.data;
  },

  async Stats({ commit }) {
    const res = await Api().get('stats');

    commit('Stats', res.data.data);

    return res.data;
  },
};

export default actions;
