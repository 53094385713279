import { GetterTree } from 'vuex';
import { State } from '@/types';

const getters: GetterTree<State.Account, State.Root> = {
  Account: (state) => state.account,
  Id: (state) => state.account?.id,
  CommissionEvents: (state) => state.commissionEvents,
  ContextEvents: (state) => state.contextEvents,
  Get: (state) => state.account,
  IsLoggedIn: (state) => !!state.account,
  Stats: (state) => state.stats,
};

export default getters;
