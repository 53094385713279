












import Vue from 'vue';

export default Vue.extend({
  name: 'BaseButton',
  props: {
    isLoading: Boolean,
  },
});
