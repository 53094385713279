/* eslint-disable import/no-cycle */
import { RouteConfig } from 'vue-router';
import {
  registerAccountSubmodules,
  registerCommissionSubmodules,
  registerDownloadSubmodule,
  registerUploadSubmodule,
} from '@/store';

const routes: RouteConfig[] = [
  {
    path: '/my',
    name: 'me',
    redirect: { name: 'home' },
    components: {
      default: () => import(/* webpackChunkName: "v-u-root" */'@/views/user/Root.vue'),
    },
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: '',
        redirect: { name: 'home' },
      },
      {
        path: '/home',
        redirect: { name: 'home' },
      },
      {
        path: 'home',
        name: 'home',
        components: {
          default: () => import(/* webpackChunkName: "v-u-root" */ '@/views/user/Home.vue'),
          menu: () => import(/* webpackChunkName: "v-u-menu" */'@/views/user/Menu.vue'),
        },
      },
      {
        path: '/home/watchlist',
        redirect: { name: 'watchlist' },
      },
      {
        path: 'watchlist',
        name: 'watchlist',
        components: {
          default: () => import(/* webpackChunkName: "v-u-wl" */ '@/views/user/Watchlist.vue'),
          menu: () => import(/* webpackChunkName: "v-u-menu" */'@/views/user/Menu.vue'),
        },
      },
      {
        path: 'uploads',
        name: 'uploads',
        beforeEnter(to, from, next) {
          registerUploadSubmodule().then(() => next());
        },
        components: {
          default: () => import(/* webpackChunkName: "v-up" */ '@/views/user/Uploads.vue'),
          menu: () => import(/* webpackChunkName: "v-u-menu" */'@/views/user/Menu.vue'),
        },
      },
      {
        path: 'downloads',
        name: 'downloads',
        beforeEnter(to, from, next) {
          registerDownloadSubmodule().then(() => next());
        },
        components: {
          default: () => import(/* webpackChunkName: "v-fl" */ '@/views/user/Downloads.vue'),
          menu: () => import(/* webpackChunkName: "v-u-menu" */'@/views/user/Menu.vue'),
        },
      },
      {
        path: 'commissions',
        name: 'commissions',
        components: {
          default: () => import(/* webpackChunkName: "v-ac-cq" */ '@/views/user/commissions/Root.vue'),
          menu: () => import(/* webpackChunkName: "v-ac-cq" */ '@/views/user/commissions/Menu.vue'),
        },
      },
      {
        path: 'commissions',
        name: 'account.commissions',
        redirect: { name: 'commissions' },
      },
      {
        path: '/auftrag/:COMMISSION_ID:route(.*)',
        redirect(to) {
          return `/my${to.fullPath}`;
        },
      },
      {
        path: 'auftrag/:COMMISSION_ID',
        components: {
          default: () => import(/* webpackChunkName: "v-c-root" */ '@/views/commission/Root.vue'),
          menu: () => import(/* webpackChunkName: "v-c-menu" */ '@/views/commission/Menu.vue'),
        },
        beforeEnter(to, from, next) {
          registerCommissionSubmodules().then(() => next());
        },
        children: [
          {
            path: '',
            name: 'commission',
            component: () => import(/* webpackChunkName: "v-c-root" */ '@/views/commission/Index.vue'),
          },
          {
            path: 'activity',
            name: 'commission.events',
            component: () => import(/* webpackChunkName: "v-c-ev" */ '@/views/commission/Events.vue'),
          },
          {
            path: 'messages',
            name: 'commission.messages',
            component: () => import(/* webpackChunkName: "v-c-chat" */ '@/views/commission/Messages.vue'),
          },
          {
            path: 'files',
            name: 'commission.files',
            component: () => import(/* webpackChunkName: "v-c-f" */ '@/views/commission/Files.vue'),
          },
          {
            path: 'upload',
            name: 'commission.upload',
            beforeEnter(to, from, next) {
              registerUploadSubmodule().then(() => next());
            },
            component: () => import(/* webpackChunkName: "v-c-up" */ '@/views/commission/Upload.vue'),
          },
          {
            path: 'files/:FILE_ID',
            name: 'commission.file',
            component: () => import(/* webpackChunkName: "v-c-f" */ '@/views/commission/File.vue'),
          },
          {
            path: 'items',
            name: 'commission.items',
            component: () => import(/* webpackChunkName: "v-c-i" */ '@/views/commission/Items.vue'),
          },
          {
            path: 'items/:ITEM_ID',
            name: 'commission.item',
            component: () => import(/* webpackChunkName: "v-c-i" */ '@/views/commission/Item.vue'),
          },
        ],
      },
      {
        path: '',
        components: {
          default: () => import(/* webpackChunkName: "v-u-ac-root" */ '@/views/user/account/Root.vue'),
          menu: () => import(/* webpackChunkName: "v-u-ac-menu" */'@/views/user/account/Menu.vue'),
        },
        async beforeEnter(to, from, next) {
          registerAccountSubmodules().then(() => next());
        },
        children: [
          {
            path: 'account',
            name: 'account',
            redirect: { name: 'account.appearance' },
            component: () => import(/* webpackChunkName: "v-ac-index" */ '@/views/user/account/Index.vue'),
          },
          {
            path: 'account',
            name: 'account.overview',
            redirect: { name: 'account' },
          },
          {
            path: 'appearance',
            name: 'account.appearance',
            component: () => import(/* webpackChunkName: "v-ac-me" */ '@/views/user/account/Appearance.vue'),
          },
          {
            path: 'maker-pages',
            name: 'account.businessses',
            component: () => import(/* webpackChunkName: "v-ac-b" */ '@/views/user/account/Businesses.vue'),
          },
          {
            path: 'maker-pages',
            name: 'account.profiles',
            redirect: {
              name: 'account.businessses',
            },
          },
          {
            path: 'emails',
            name: 'account.emails',
            component: () => import(/* webpackChunkName: "v-ac-e" */ '@/views/user/account/Emails.vue'),
          },
          {
            path: '/change-password',
            redirect: {
              name: 'account.passphrase',
            },
          },
          {
            path: 'password',
            name: 'account.password',
            redirect: {
              name: 'account.passphrase',
            },
          },
          {
            path: 'passphrase',
            name: 'account.passphrase',
            component: () => import(/* webpackChunkName: "v-ac-pw" */ '@/views/user/account/Password.vue'),
          },
          {
            path: 'totp',
            name: 'account.totp',
            component: () => import(/* webpackChunkName: "v-ac-totp" */ '@/views/user/account/Totp.vue'),
          },
          {
            path: 'u2f',
            name: 'account.u2f',
            redirect: { name: 'account.pkc' },
          },
          {
            path: 'public-key-credentials',
            name: 'account.pkc',
            component: () => import(/* webpackChunkName: "v-ac-pkc" */ '@/views/user/account/PublicKeyCredentials.vue'),
          },
          {
            path: 'data',
            name: 'account.data',
            component: () => import(/* webpackChunkName: "v-ac-data" */ '@/views/user/account/Data.vue'),
            beforeEnter(to, from, next) {
              registerCommissionSubmodules().then(() => next());
            },
          },
        ],
      },
    ],
  },
];

export default routes;
