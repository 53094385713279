import { render, staticRenderFns } from "./Level.vue?vue&type=template&id=466f9ae2&scoped=true&"
import script from "./Level.vue?vue&type=script&lang=ts&"
export * from "./Level.vue?vue&type=script&lang=ts&"
import style0 from "./Level.vue?vue&type=style&index=0&id=466f9ae2&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466f9ae2",
  null
  
)

export default component.exports