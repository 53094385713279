import Vue from 'vue';

const req = require.context(
  '@/components/base',
  false,
  /\.vue$/,
);

req.keys().forEach((filename) => {
  const required = req(filename);
  const component = required.default || required;

  // extendOptions is the arg of Vue.extend(extendOptions)
  const name = component.extendOptions ? component.extendOptions.name : component.name;

  Vue.component(name, component);
});

Vue.component('I18nRouterLink', require('@/components/i18n/RouterLink.vue').default);

Vue.component('BasePricingTable', () => import(/* webpackChunkName: "b-pt" */'@/components/base_async/PricingTable.vue'));

Vue.component('AuthenticationAttempt', () => import(/* webpackChunkName: "auth" */'@/components/auth/Attempt.vue'));
Vue.component('ErrorMessage', () => import(/* webpackChunkName: "f" */'@/components/ErrorMessage.vue'));

Vue.component('FormCheckbox', () => import(/* webpackChunkName: "f" */'@/components/form/Checkbox.vue'));
Vue.component('FormField', () => import(/* webpackChunkName: "f" */'@/components/form/Field.vue'));
Vue.component('FormInput', () => import(/* webpackChunkName: "f" */'@/components/form/Input.vue'));
Vue.component('FormInputCopy', () => import(/* webpackChunkName: "f-cp" */'@/components/form/InputCopy.vue'));
Vue.component('FormInputDate', () => import(/* webpackChunkName: "f" */'@/components/form/InputDate.vue'));
Vue.component('FormInputDateTime', () => import(/* webpackChunkName: "f" */'@/components/form/InputDateTime.vue'));
Vue.component('FormInputFiles', () => import(/* webpackChunkName: "f" */'@/components/form/InputFiles.vue'));
Vue.component('FormInputMoney', () => import(/* webpackChunkName: "f-money" */'@/components/form/InputMoney.vue'));
Vue.component('FormRadio', () => import(/* webpackChunkName: "f" */'@/components/form/Radio.vue'));
Vue.component('FormSelect', () => import(/* webpackChunkName: "f" */'@/components/form/Select.vue'));
Vue.component('FormSelectCountry', () => import(/* webpackChunkName: "f-select-country" */'@/components/form/SelectCountry.vue'));
Vue.component('FormTextarea', () => import(/* webpackChunkName: "f" */'@/components/form/Textarea.vue'));
Vue.component('FormLabel', () => import(/* webpackChunkName: "f" */'@/components/form/Label.vue'));
Vue.component('FormValidationError', () => import(/* webpackChunkName: "f" */'@/components/form/ValidationError.vue'));
Vue.component('TransitionValidation', () => import(/* webpackChunkName: "f" */'@/components/transition/Validation.vue'));

Vue.component('MediaComponent', () => import(/* webpackChunkName: "media" */'@/components/media/Media.vue'));

Vue.component('MenuComponent', () => import(/* webpackChunkName: "menu" */'@/components/menu/Menu.vue'));
Vue.component('MenuLabel', () => import(/* webpackChunkName: "menu" */'@/components/menu/Label.vue'));
Vue.component('MenuLink', () => import(/* webpackChunkName: "menu" */'@/components/menu/Link.vue'));
Vue.component('MenuLinkBack', () => import(/* webpackChunkName: "menu" */'@/components/menu/LinkBack.vue'));
Vue.component('MenuList', () => import(/* webpackChunkName: "menu" */'@/components/menu/List.vue'));
Vue.component('MenuView', () => import(/* webpackChunkName: "menu" */'@/components/menu/View.vue'));
Vue.component('TransitionFadeSnappy', require('@/components/transition/FadeSnappy.vue').default);

Vue.component('TransitionFadeFast', require('@/components/transition/FadeFast.vue').default);

Vue.component('ConditionalComponent', require('@/components/Conditional.vue').default);
Vue.component('ListComponent', require('@/components/List.vue').default);

Vue.component('NotFound', () => import(/* webpackChunkName: "not-found" */'@/components/NotFound.vue'));

// SVG Logo
Vue.component('SvgLogo', () => import(/* webpackChunkName: "svg-auf" */'@/components/svg/Logo.vue'));
// SVG Arrows
Vue.component('SvgArrowDown', () => import(/* webpackChunkName: "svg-a" */'@/components/svg/ArrowDown.vue'));
Vue.component('SvgArrowRight', () => import(/* webpackChunkName: "svg-a" */'@/components/svg/ArrowRight.vue'));
Vue.component('SvgArrowLeft', () => import(/* webpackChunkName: "svg-a" */'@/components/svg/ArrowLeft.vue'));
Vue.component('SvgArrowUpDown', () => import(/* webpackChunkName: "svg-a" */'@/components/svg/ArrowUpDown.vue'));
// SVG Icons
Vue.component('SvgAtSign', () => import(/* webpackChunkName: "svg-i" */'@/components/svg/AtSign.vue'));
Vue.component('SvgCheck', () => import(/* webpackChunkName: "svg-i" */'@/components/svg/Check.vue'));
Vue.component('SvgPlus', () => import(/* webpackChunkName: "svg-i" */'@/components/svg/Plus.vue'));
Vue.component('SvgStar', () => import(/* webpackChunkName: "svg-i" */'@/components/svg/Star.vue'));
Vue.component('SvgX', () => import(/* webpackChunkName: "svg-i" */'@/components/svg/X.vue'));
// SVG Circles
Vue.component('SvgCheckCircle', () => import(/* webpackChunkName: "svg-c" */'@/components/svg/CheckCircle.vue'));
Vue.component('SvgInfoCircle', () => import(/* webpackChunkName: "svg-c" */'@/components/svg/InfoCircle.vue'));
Vue.component('SvgMessageCircle', () => import(/* webpackChunkName: "svg-c" */'@/components/svg/MessageCircle.vue'));
Vue.component('SvgPlusCircle', () => import(/* webpackChunkName: "svg-c" */'@/components/svg/PlusCircle.vue'));
Vue.component('SvgXCircle', () => import(/* webpackChunkName: "svg-c" */'@/components/svg/XCircle.vue'));
// SVG Chevrons
Vue.component('SvgChevronDown', () => import(/* webpackChunkName: "svg-ch" */'@/components/svg/ChevronDown.vue'));
// SVG Emotes
Vue.component('SvgEmoteFrown', () => import(/* webpackChunkName: "svg-e" */'@/components/svg/EmoteFrown.vue'));
Vue.component('SvgEmoteMeh', () => import(/* webpackChunkName: "svg-e" */'@/components/svg/EmoteMeh.vue'));
Vue.component('SvgEmoteSmile', () => import(/* webpackChunkName: "svg-e" */'@/components/svg/EmoteSmile.vue'));
// SVG Currency
Vue.component('SvgEur', () => import(/* webpackChunkName: "svg-eur" */'@/components/svg/Eur.vue'));
// SVG Files
Vue.component('SvgFile', () => import(/* webpackChunkName: "svg-f" */'@/components/svg/File.vue'));
Vue.component('SvgFileMinus', () => import(/* webpackChunkName: "svg-f" */'@/components/svg/FileMinus.vue'));
Vue.component('SvgFilePlus', () => import(/* webpackChunkName: "svg-f" */'@/components/svg/FilePlus.vue'));
Vue.component('SvgFileText', () => import(/* webpackChunkName: "svg-f" */'@/components/svg/FileText.vue'));
Vue.component('SvgFileInvoice', () => import(/* webpackChunkName: "svg-f-invoice" */'@/components/svg/FileInvoice.vue'));
Vue.component('SvgFolder', () => import(/* webpackChunkName: "svg-f" */'@/components/svg/Folder.vue'));
// SVG Hexagons
Vue.component('SvgHex', () => import(/* webpackChunkName: "svg-hex" */'@/components/svg/Hex.vue'));
Vue.component('SvgHexCircle', () => import(/* webpackChunkName: "svg-hex" */'@/components/svg/HexCircle.vue'));
// SVG Misc
Vue.component('SvgActivity', () => import(/* webpackChunkName: "svg-activity" */'@/components/svg/Activity.vue'));
Vue.component('SvgBanknote', () => import(/* webpackChunkName: "svg-banknote" */'@/components/svg/Banknote.vue'));
Vue.component('SvgBriefcase', () => import(/* webpackChunkName: "svg-briefcase" */'@/components/svg/Briefcase.vue'));
Vue.component('SvgDownload', () => import(/* webpackChunkName: "svg-dl" */'@/components/svg/Download.vue'));
Vue.component('SvgFilter', () => import(/* webpackChunkName: "svg-filter" */'@/components/svg/Filter.vue'));
Vue.component('SvgFlag', () => import(/* webpackChunkName: "svg-flag" */'@/components/svg/Flag.vue'));
Vue.component('SvgGlobe', () => import(/* webpackChunkName: "svg-globe" */'@/components/svg/Globe.vue'));
Vue.component('SvgHome', () => import(/* webpackChunkName: "svg-home" */'@/components/svg/Home.vue'));
Vue.component('SvgImage', () => import(/* webpackChunkName: "svg-image" */'@/components/svg/Image.vue'));
Vue.component('SvgInbox', () => import(/* webpackChunkName: "svg-inbox" */'@/components/svg/Inbox.vue'));
Vue.component('SvgKey', () => import(/* webpackChunkName: "svg-key" */'@/components/svg/Key.vue'));
Vue.component('SvgLink', () => import(/* webpackChunkName: "svg-link" */'@/components/svg/Link.vue'));
Vue.component('SvgList', () => import(/* webpackChunkName: "svg-list" */'@/components/svg/List.vue'));
Vue.component('SvgLogOut', () => import(/* webpackChunkName: "svg-logout" */'@/components/svg/LogOut.vue'));
Vue.component('SvgMenu', () => import(/* webpackChunkName: "svg-menu" */'@/components/svg/Menu.vue'));
Vue.component('SvgOrderAsc', () => import(/* webpackChunkName: "svg-order" */'@/components/svg/OrderAsc.vue'));
Vue.component('SvgOrderAscArrow', () => import(/* webpackChunkName: "svg-order-arrow" */'@/components/svg/OrderAscArrow.vue'));
Vue.component('SvgOrderDesc', () => import(/* webpackChunkName: "svg-order" */'@/components/svg/OrderDesc.vue'));
Vue.component('SvgOrderDescArrow', () => import(/* webpackChunkName: "svg-order-arrow" */'@/components/svg/OrderDescArrow.vue'));
Vue.component('SvgPaperplane', () => import(/* webpackChunkName: "svg-paperplane" */'@/components/svg/Paperplane.vue'));
Vue.component('SvgSettings', () => import(/* webpackChunkName: "svg-settings" */'@/components/svg/Settings.vue'));
Vue.component('SvgShoppingBag', () => import(/* webpackChunkName: "svg-shopping-bag" */'@/components/svg/ShoppingBag.vue'));
Vue.component('SvgSliders', () => import(/* webpackChunkName: "svg-sliders" */'@/components/svg/Sliders.vue'));
// SVG Lock
Vue.component('SvgLock', () => import(/* webpackChunkName: "svg-l" */'@/components/svg/Lock.vue'));
Vue.component('SvgUnlock', () => import(/* webpackChunkName: "svg-l" */'@/components/svg/Unlock.vue'));
// SVG Magnifying Glass
Vue.component('SvgSearch', () => import(/* webpackChunkName: "svg-s" */'@/components/svg/Search.vue'));
// Svg Clock
Vue.component('SvgClock0400', () => import(/* webpackChunkName: "svg-t" */'@/components/svg/Clock0400.vue'));
// SVG User
Vue.component('SvgUser', () => import(/* webpackChunkName: "svg-u" */'@/components/svg/User.vue'));
Vue.component('SvgUserCheck', () => import(/* webpackChunkName: "svg-u" */'@/components/svg/UserCheck.vue'));
// SVG Upload
Vue.component('SvgUploadCloud', () => import(/* webpackChunkName: "svg-up" */'@/components/svg/UploadCloud.vue'));
