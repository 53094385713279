<template>
  <section
    :is="tag"
    class="hero"
  >
    <div class="hero-head">
      <slot name="head" />
    </div>
    <div
      :class="{'container': hasBodyContainer}"
      class="hero-body"
    >
      <slot name="default" />
    </div>
    <div class="hero-foot">
      <slot name="foot" />
    </div>
  </section>
</template>

<script lang="js">
export default {
  name: 'BaseHero',
  props: {
    hasBodyContainer: Boolean,
    tag: {
      type: String,
      required: false,
      default: 'section',
    },
  },
};
</script>

<style lang="sass">
@import "@/styles/_bulma-customization.sass"
@import "~bulma/sass/layout/hero.sass"
</style>
