/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable class-methods-use-this */

import { AnalyticsInterface } from './index';

export class ConsoleLogAnalytics implements AnalyticsInterface {
  goals = {
    UserRegister: 'RegisterUserGoalID', // ✔
    MakerCreate: 'CreateMakerGoalID', // ✔
    PricingPlusUpgrade: 'PlusPricingUpgradeGoalID', // ✔
    WatchlistAdd: 'AddToWatchlistGoalID', // ✔
    CommissionItemCreate: 'CreateCommissionItemGoalID', // ✔
    CommissionRequestSubmit: 'SubmitRequestGoalID', // ✔
    CommissionRequestAccept: 'AcceptCommissionRequestGoalID', // ✔
    CommissionItemPay: 'PayCommissionItemGoalID', // ✔
    CommissionFileUpload: 'UploadCommissionFileGoalID',
    CommissionComplete: 'CompleteCommissionGoalID',
    CommissionFinished: 'FinishedCommissionGoalID',
  }

  pageview(canonicalUrl?: string, referrerUrl?: string): void {
    if (!canonicalUrl) {
      canonicalUrl = window.location.href;
      console.debug(canonicalUrl);
    }
    if (!canonicalUrl) {
      canonicalUrl = document.createElement('a').href;
      console.debug(canonicalUrl);
    }
    if (!canonicalUrl) {
      canonicalUrl = document.querySelector<HTMLLinkElement>('link[rel="canonical"][href]')?.href;
      console.debug(canonicalUrl);
    }
    if (!referrerUrl && !document.referrer.includes(window.location.hostname)) {
      referrerUrl = document.referrer || '';
    }

    console.debug('[Analytics][Pageview]', 'Canonical URL:', canonicalUrl, 'Referrer URL:', referrerUrl);
  }

  goal(goalId: string, valueInCents = 0): void {
    console.debug('[Analytics][Goal]', 'Goal ID:', goalId, 'Goal Value:', valueInCents);
  }
}

export function createConsoleLogAnalytics(): ConsoleLogAnalytics {
  return new ConsoleLogAnalytics();
}
