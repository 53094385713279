
import Vue from 'vue';

export default Vue.extend({
  mounted() {
    this.$stripe().then((stripe) => {
      console.debug('[Stripe.js] Loaded', stripe);
    });
  },
  render: (h) => h(),
});
