import Vue from 'vue';
import Meta, { MetaInfo /* MetaInfoComputed */ } from 'vue-meta';

// https://vue-meta.nuxtjs.org/api/
Vue.use(Meta, {
  keyName: 'meta',
  attribute: 'vm',
  // ssrAttribute: 'vm-ssr',
  tagIDKeyName: 'vmID',
});

const meta = {
  titleTemplate(chunk: string) {
    return chunk ? `${chunk} / Auftrag.app` : 'Auftrag.app';
  },
};

export default meta;

type MetaInfoComputed = () => MetaInfo;

declare module 'vue/types/options' {
  interface ComponentOptions<V extends Vue> {
    meta?: MetaInfo | MetaInfoComputed,
  }
}
