import { RouteConfig } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import { createLocalizedRoute, createRedirect } from '@/router';

const routes: RouteConfig[] = [
  createLocalizedRoute('pricing.fee', () => import(/* webpackChunkName: "v-p-fee" */ '@/views/about/pricing/Fee.vue'), {
    en: '/en/pricing',
    de: '/de/preise',
  }),
  createRedirect('/pricing', 'pricing.fee#en'),
  createRedirect('/preise', 'pricing.fee#de'),

  createLocalizedRoute('pricing.flex', () => import(/* webpackChunkName: "v-p-flex" */ '@/views/about/pricing/Flex.vue'), {
    en: '/en/flex',
    de: '/de/flex',
  }),
  createRedirect('/flex', 'pricing.flex#en'),
  createRedirect('/pricing/cloud-storage', 'pricing.flex#en'),
  createRedirect('/de/preise/cloudspeicher', 'pricing.flex#de'),

  createLocalizedRoute('pricing.plus', () => import(/* webpackChunkName: "v-p-plus" */'@/views/about/pricing/Plus.vue'), {
    en: '/en/plus',
    de: '/de/plus',
  }),
  createRedirect('/plus', 'pricing.plus#en'),

  createLocalizedRoute('audience.customers', () => import(/* webpackChunkName: "v-aud-cust" */ '@/views/about/audience/Customers.vue'), {
    en: '/en/for/customers',
    de: '/de/für-kunden',
  }),
  createRedirect('/for/customers', 'audience.customers#en'),

  {
    path: '/for/customers/how-it-works',
    name: 'audience.customers.hiw',
    component: () => import(/* webpackChunkName: "v-hiw-cust" */ '@/views/about/HowItWorksForCustomers.vue'),
  },

  createLocalizedRoute('audience.makers', () => import(/* webpackChunkName: "v-aud-maker" */ '@/views/about/audience/Makers.vue'), {
    en: '/en/for/makers',
    de: '/de/für-maker',
  }),
  createRedirect('/for/makers', 'audience.makers#en'),
  {
    path: '/for/makers/how-it-works',
    name: 'audience.makers.hiw',
    component: () => import(/* webpackChunkName: "v-hiw-maker" */ '@/views/about/HowItWorksForMakers.vue'),
  },

  createLocalizedRoute('changelog', () => import(/* webpackChunkName: "v-cl" */ '@/views/about/Changelog.vue'), {
    en: '/en/changelog',
    de: '/de/changelog',
  }),
  createRedirect('/changelog', 'changelog#en'),
];

export default routes;
