import { DateTimeFormats } from 'vue-i18n';

const n = 'numeric';
const s = 'short';

const DATE = {
  year: n,
  month: s,
  day: n,
};

const DATE_TIME = {
  year: n,
  month: s,
  day: n,
  hour: n,
  minute: n,
};

const TIMESTAMP = {
  year: n,
  month: s,
  day: n,
  weekday: s,
  hour: n,
  minute: n,
  second: n,
};

const MONTH_YEAR = {
  year: n,
  month: 'long',
};

// https://kazupon.github.io/vue-i18n/guide/datetime.html
// $d(new Date(), 'format', 'locale')
const dates: DateTimeFormats = {
  en: {
    DATE,
    DATE_TIME,
    TIMESTAMP,
    MONTH_YEAR,
  },
  de: {
    DATE,
    DATE_TIME,
    TIMESTAMP,
    MONTH_YEAR,
  },
};

export default dates;
