




















































import Vue from 'vue';

export default Vue.extend({
  name: 'BaseParagraph',
  props: {
    translationObject: {
      type: Object,
      required: true,
    },
    paragraphLevel: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  computed: {
    title(): string {
      return this.translationObject.title;
    },
    hasTitle(): boolean {
      return !!this.title;
    },
    hasText(): boolean {
      return !!this.translationObject.text;
    },
    hasParagraphs(): boolean {
      if (!this.translationObject.paragraphs) return false;

      return Object.keys(this.translationObject.paragraphs).length > 0;
    },
  },
});
