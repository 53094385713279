import { GetterTree } from 'vuex';
import { ApiResource, State } from '@/types';

const getters: GetterTree<State.Vanity, State.Root> = {
  IsAvailable: (s) => (vanity: string): boolean => !!s.list
    .filter((v) => v.vanity === vanity.toLowerCase())
    .filter((v) => v.available)[0],
  IsChecked: (s) => (vanity: string): boolean => s.list
    .filter((v) => v.vanity === vanity.toLowerCase()).length > 0,
  Find: (s) => (vanity: string): ApiResource.Vanity | undefined => s.list
    .filter((v) => v.vanity === vanity.toLowerCase())[0],
};

export default getters;
