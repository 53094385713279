import { ActionTree } from 'vuex';
import { Route } from 'vue-router';
import { State } from '@/types';
// eslint-disable-next-line import/no-cycle
import { Api } from '@/http';

const actions: ActionTree<State.Root, State.Root> = {
  async CapturePerformance({ commit }) {
    const { timing } = window.performance;

    // https://support.cloudflare.com/hc/en-us/articles/360033929991-Cloudflare-Browser-Insights
    const performance = {
      total: null as number | null,
      dns: null as number | null,
      tcp: null as number | null,
      req: null as number | null,
      res: null as number | null,
      dom: null as number | null,
      load: null as number | null,

      wait: null as number | null,
      int: null as number | null,
    };
    const total = timing.domComplete - timing.navigationStart;
    const dns = timing.domainLookupEnd - timing.domainLookupStart;
    const tcp = timing.connectEnd - timing.connectStart;
    const req = timing.responseStart - timing.requestStart;
    const res = timing.responseEnd - timing.responseStart;
    const dom = timing.domComplete - timing.domLoading;
    const load = timing.loadEventEnd - timing.loadEventStart;
    const wait = timing.domComplete - timing.fetchStart;
    const int = timing.fetchStart - timing.domInteractive;

    if (total >= 0) performance.total = total;
    if (dns >= 0) performance.dns = dns;
    if (tcp >= 0) performance.tcp = tcp;
    if (req >= 0) performance.req = req;
    if (res >= 0) performance.res = res;
    if (dom >= 0) performance.dom = dom;
    if (load >= 0) performance.load = load;
    if (wait >= 0) performance.wait = wait;
    if (int >= 0) performance.int = int;

    commit('SetPerformance', performance);
  },
  async info({ commit }) {
    let res;

    try {
      res = await Api().get('/cdn-edge/info', { headers: { Authorization: null } });
    } catch (e) {
      commit('SetApiStatus', 'CF-down');
    }

    if (!res?.data?.data) return;

    const data = res?.data?.data;

    commit('SetApiStatus', data.api.status);

    if (data.client.country !== 'XX') {
      commit('SetCountry', data.client.country);
    }

    commit('SetLatestBuildId', data.web.build_id);
  },
  async SendBeacon({ commit, dispatch, getters }, { action, route }: {
    action: string,
    route?: Route,
  }) {
    await dispatch('CapturePerformance');

    const sent = navigator.sendBeacon(`${process.env.VUE_APP_API_URL}/b`, JSON.stringify({
      a: action,
      b: getters.BUILD_ID,
      c: getters.COMMIT_REF,
      // i: undefined,
      n: getters.BEACON_COUNT,
      p: getters.BEACON_COUNT === 0 ? getters.PERFORMANCE : undefined,
      r: route?.meta?.id || route?.name || undefined,
      s: getters.AB_SEGMENT,
      // t: Date.now(),
    }));

    if (sent) commit('IncrementBeaconCount');
  },
  async VerifyEmail(context, { EMAIL_ID, form } = {}) {
    const res = await Api().post(`emails/${EMAIL_ID}`, form);

    return !!res;
  },
};

export default actions;
