/* eslint-disable import/no-cycle */
import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import storeOptions from '@/modules/store';
import { State } from '@/types';

Vue.use(Vuex);

const store = new Store<State.Root>(storeOptions);

export default store;

async function registerSubmodule(
  path: string,
  modulePromise: Promise<any>,
): Promise<void> {
  if (store.hasModule(path)) return void 0; // Already registered

  const module = await modulePromise;

  // Prevent race-condition by checking again after awaiting
  if (!store.hasModule(path)) store.registerModule(path, module.default);

  return void console.debug('[Store] +Submodule:', path);
}

export function registerDownloadSubmodule(): Promise<any> {
  return registerSubmodule('download', import(/* webpackChunkName: "x-dl" */'@/modules/download'));
}

export function registerUploadSubmodule(): Promise<any> {
  return registerSubmodule('upload', import(/* webpackChunkName: "x-u" */'@/modules/upload'));
}

export function registerAccountSubmodules(): Promise<any[]> {
  const all = [
    registerSubmodule('account/email', import(/* webpackChunkName: "x-a" */'@/modules/account/email')),
    registerSubmodule('account/mfa', import(/* webpackChunkName: "x-a" */'@/modules/account/mfa')),
  ];

  return Promise.all(all);
}

export function registerBusinessSubmodules(): Promise<any[]> {
  const all = [
    registerSubmodule('business/commission', import(/* webpackChunkName: "x-b" */'@/modules/business/commission')),
    registerSubmodule('business/event', import(/* webpackChunkName: "x-b" */'@/modules/business/event')),
    registerSubmodule('business/ExternalAccounts', import(/* webpackChunkName: "x-b" */'@/modules/business/external_accounts')),
    registerSubmodule('business/Pages', import(/* webpackChunkName: "x-b" */'@/modules/business/pages')),
    registerSubmodule('business/Fees', import(/* webpackChunkName: "x-b" */'@/modules/business/fees')),
    registerSubmodule('business/Payments', import(/* webpackChunkName: "x-b" */'@/modules/business/payments')),
    registerSubmodule('business/Payouts', import(/* webpackChunkName: "x-b" */'@/modules/business/payouts')),
    registerSubmodule('business/Vouchers', import(/* webpackChunkName: "x-b" */'@/modules/business/vouchers')),
  ];

  return Promise.all(all);
}

export function registerBusinessCommissionSubmodules(): Promise<any[]> {
  const all = [
    registerSubmodule('business/commission/event', import(/* webpackChunkName: "x-bc" */'@/modules/business/commission/event')),
    registerSubmodule('business/commission/file', import(/* webpackChunkName: "x-bc" */'@/modules/business/commission/file')),
    registerSubmodule('business/commission/item', import(/* webpackChunkName: "x-bc" */'@/modules/business/commission/item')),
    registerSubmodule('business/commission/messages', import(/* webpackChunkName: "x-bc" */'@/modules/business/commission/messages')),
    registerSubmodule('business/commission/trackers', import(/* webpackChunkName: "x-bc" */'@/modules/business/commission/trackers')),
  ];

  return Promise.all(all);
}

export function registerCommissionSubmodules(): Promise<any[]> {
  const all = [
    registerSubmodule('commission/event', import(/* webpackChunkName: "x-c" */'@/modules/commission/event')),
    registerSubmodule('commission/file', import(/* webpackChunkName: "x-c" */'@/modules/commission/file')),
    registerSubmodule('commission/item', import(/* webpackChunkName: "x-c" */'@/modules/commission/item')),
    registerSubmodule('commission/message', import(/* webpackChunkName: "x-c" */'@/modules/commission/message')),
    registerSubmodule('commission/tracker', import(/* webpackChunkName: "x-c" */'@/modules/commission/tracker')),
  ];

  return Promise.all(all);
}
