/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ApiResource, State } from '@/types';
import { pushResource } from '@/util/list';

const mutations: MutationTree<State.Account> = {
  SetAccount(state, payload: ApiResource.Account) {
    state.account = payload;
  },
  UnsetAccount(state) {
    state.account = null;
  },
  PushCommissionEvent(state, payload: ApiResource.Event) {
    pushResource(state.commissionEvents, payload);
  },
  PushCommissionEventList(state, { data }: { data: ApiResource.Event[], meta: any }) {
    data.forEach((res) => pushResource(state.commissionEvents, res));
  },
  PushContextEvent(state, payload: ApiResource.Event) {
    pushResource(state.contextEvents, payload);
  },
  PushContextEventList(state, { data }: { data: ApiResource.Event[], meta: any }) {
    data.forEach((res) => pushResource(state.contextEvents, res));
  },
  Stats(state, stats) {
    state.stats = stats;
  },
};

export default mutations;
